
import { Table, Space, Popconfirm, Tag, Dropdown, SideSheet, Form, Modal, Button, Toast, Divider } from '@douyinfe/semi-ui';
import { useState, useRef, useEffect } from "react";
import { IconTreeTriangleDown } from "@douyinfe/semi-icons";
import i from "@/api/axiosInstance";
import { useAppContext } from "@/context/context";
import AddCouponCode from "@/components/AddCouponCode";

export default function DiscountCoupon() {



    const { setLoadingShow } = useAppContext();  //加载层隐藏或者显示 

    const columns = [
        {
            title: '优惠券ID',
            dataIndex: '优惠券ID',

            render: (text, record, index) => {
                return (<><div>{text}</div> {record.方案编码 && <Tag color='red' >限定方案：{record.方案编码} </Tag>}</>);
            }
        },
        {
            title: '优惠券名称',
            dataIndex: '优惠券名称',
        },
 
        {
            title: '同步时间',
            dataIndex: '最新同步时间',
        },
        {
            title: 'sid',
            dataIndex: 'sid',
             render: (text, record, index) => { 
                 return (<>{text} <Tag color="green" > {record.手机号} </Tag></>)

            }
        },


        {
            title: '入库状态',
            dataIndex: '入库状态',
            render: (text, record, index) => {


                return (<Tag color={text == "正常" ? "green" : "red"} > {text} </Tag>)

            }
        }, 
        {
            title: '异常',
            dataIndex: '异常'
        },
        {
            title: '未使用',
            render: (text, record, index) => {


                return (record.总数量 - record.异常 - record.已使用);

            }
        },
        {
            title: '已使用',
            dataIndex: '已使用'
        },
        {
            title: '总数量',
            dataIndex: '总数量'
        },
        {
            title: '操作',
            dataIndex: 'owner',
            render: (text, row, index) => {

                return (<Space>


                    <Button size="small" onClick={() => {  
                        sync_coupon({ syncList: [{ 方案编码: row.方案编码, 备注: row.备注, sid: row.sid, 优惠券ID: row.优惠券ID}]})
                     }}
                      theme='solid'>同步</Button> 
                    <Dropdown
                        render={
                            <Dropdown.Menu>
                             
                                <Popconfirm
                                    title="是否要删除"
                                    onConfirm={() => { deleteDate([{sid:row.sid,优惠券ID:row.优惠券ID}]) }}
                                >
                                    <Dropdown.Item >删除</Dropdown.Item>
                                </Popconfirm>
                            </Dropdown.Menu>
                        } trigger="hover" position="bottomRight">




                        <Button size="small" theme="light" type="primary" icon={<IconTreeTriangleDown />}></Button>
                    </Dropdown>
                </Space>);
            },
        }
    ];



    function change_status1(field,state) { // value 入库或者出库状态


        let list = [];
        if (selectedRows.length < 1) { Toast.info({ content: "最少需要选中一条数据" }); return false; }
        for (const v of selectedRows) {
            list.push({ sid: v.sid, 优惠券ID: v.优惠券ID,field:field, value: state });
        }
 

    i.post("s.php?f=coupon_stutas1", { list: list }).then(r => {
 
            Toast.info({ "content": r.data.msg });

            if (r.data.code == 1) { getList(); }

        })

    }


    async function deleteDate(list) {

        const r = await i.post("s.php?f=delete_coupon", {list:list, b: "mdl_coupon_of_sid" });
        Toast.info({ "content": r.data.msg });
        getList();
    }




    const formApi1 = useRef(); //获取搜索表单1 API

   


    const [data, setData] = useState([]);

    let limit = 30; //分页页数
    async function getList(page) {

        page = page ? page : currentPage;

        const r1 = await i.post(`s.php?f=get_coupon1&limit=${limit}&page=${page}`, formApi1.current.getValues());


        setData(r1.data);

    }
  
    useEffect(() => {

        formApi1.current.setValues({ "入库状态":"0"});
 

        getList(1);  //获取第一页商品
 
 
    }, [])


    const [SlideVisible, setSlideVisible] = useState(false); //侧边栏显示或隐藏


 


  async function sync_coupon(values){  //同步优惠券 
      setLoadingShow(true);
  let r=await i.post("s.php?f=sync_coupon1", { ...values }); 
      setLoadingShow(false);
     
      Modal.info({
          title: '操作成功', hasCancel: false,
          width: "600px",
           content: <><p style={{ fontSize: "16px", lineHeight: "1.5" }}>
              新增券数量:{r.data.succeed} <br /> 
              更新券数量:{r.data.repetition} <br /> 
               {r.data.error > 0 && <Tag>向数据库添加数据时出错,数量:{r.data.error}</Tag>}
               {r.data.info.map((v, index) => <Tag key={index} color="red">{v}</Tag>)}
          </p></>
      });

      getList();


      return r;


 }




    const [currentPage, setPage] = useState(1);

    const [selectedRows, setSelectedRows]=useState([]);
    return (<>
        <Space> 
            <Form getFormApi={formApi => formApi1.current = formApi} layout='horizontal' >

                <Form.Input
                    placeholder="输入关键字搜索"
                    field='key'
                    label={{
                        text: '搜索'

                    }}
                    style={{ width: 176 }}
                />
                <Form.Select field="入库状态" label="状态" >
                    <Form.Select.Option value="0">全部</Form.Select.Option>
                    <Form.Select.Option value="正常">正常</Form.Select.Option>
                    <Form.Select.Option value="出库">出库</Form.Select.Option> 
                </Form.Select>

                <Space style={{ display: 'flex', alignItems: 'flex-end' }}>
                    <Button type='primary' onClick={() => getList(1)} theme='solid'>搜索</Button>
                    <Button onClick={() => { setTimeout(() => { getList(1); }, 200); }} htmlType="reset">重置</Button>

                    <Button type='primary'   onClick={() => setSlideVisible(true)} theme='solid' >导入</Button>




                    <Button type='warning'  style={{marginLeft:50}} onClick={() => {  
let t1=[]; 
                        for (const v of selectedRows) { 
                            t1.push({ 方案编码: v.方案编码, 备注: v.备注, sid: v.sid, 优惠券ID: v.优惠券ID }); 
         } 
                        sync_coupon({ syncList: t1 }); 

                     }} theme='solid' >批量同步</Button>




                    <Popconfirm
                        title="是否要同步所有券码"
                        onConfirm={() => { sync_coupon({"syncAll":true})}}
                    > 
                    <Button type='primary' theme='solid' >同步所有券码</Button>
                    </Popconfirm>

                   

                    <Button type='warning' onClick={() => { change_status1("入库状态","出库") }} theme="solid"  >出库</Button>
                    <Button type='warning' onClick={() => { change_status1("入库状态","正常") }} theme="solid"  >入库</Button>

                    <Button type='warning' onClick={() => { change_status1("置顶", "1") }} theme="solid" >置顶(优先使用)</Button>
                    <Button type='warning' onClick={() => { change_status1("置顶", "0") }} theme="solid" >取消置顶</Button>

                    <Popconfirm
                        title="是否要删除"
                        onConfirm={() => {
                            
 
                            let t1 = [];
                            for (const v of selectedRows) {
                                t1.push({ sid: v.sid, 优惠券ID: v.优惠券ID });
                            }  
                            deleteDate(t1) ;
                        
                        
                        }}
                    >
                        <Button type='danger' theme='solid' >批量删除</Button>

                    </Popconfirm>
                 




                </Space>



            </Form>





        </Space>
        <Divider margin='12px'>
        </Divider>
        <Table columns={columns} rowSelection={{ 
            onChange: (selectedRowKeys, selectedRows) => { 
                setSelectedRows(selectedRows);
            },
        }} dataSource={data.data} rowKey="id" pagination={{
            currentPage,
            pageSize: limit,
            total: data.count,
            onPageChange: (page) => { setPage(page); getList(page); },
            }} scroll={{ y: window.innerHeight - 285 }} />

 
        <AddCouponCode SlideVisible={SlideVisible} setSlideVisible={setSlideVisible}  sync_coupon={sync_coupon}></AddCouponCode>
         


    </>);

}