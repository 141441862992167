
import { Card, Avatar, Toast, Divider, Badge, Collapse, Tag, SideSheet, Col, Row, RadioGroup, Radio, Modal, Space, Button, Typography } from "@douyinfe/semi-ui";
import { IconTickCircle, IconPlus, IconMinus } from "@douyinfe/semi-icons";

import { useAppContext } from "../../context/context";
import { MD5 } from 'crypto-js';

import i from "@/api/axiosInstance";
import { useState, useEffect, useRef } from "react";
import { useSearchParams, useNavigate } from 'react-router-dom';


export default function OnlineOrder() {


    let style2 = { border: "2px solid #ffce4d", background: "#fafafa",filter: "grayscale(0%)" };
    let style1 = { marginTop: "10px", display: "grid", gridTemplateColumns: "1fr 1fr 1fr", gridGap: "10px 5px" };//餐品框样式
    let style3 = { textAlign: "center", color: "#999", display: "block" };

    const navigate = useNavigate();



    const { setLoadingShow } = useAppContext();  //加载层隐藏或者显示 
    const { sid } = useAppContext();  //全局sid

    const { Meta } = Card;  //卡片内的组件
    const { Text } = Typography;




    const [searchParams] = useSearchParams();


    const [SlideVisible, setSlideVisible] = useState(false); //商品列表

    const [SlideVisible2, setSlideVisible2] = useState({ d: "", show: false }); //套餐内餐品定制列表


    const [SlideDataIndex, setSlideDataIndex] = useState(""); //商品列表

    const [OrderIndex, setOrderIndex] = useState(0); //商品列表


    const storeCode = searchParams.get('storeCode');  //获取URL 的参数

    const oid = searchParams.get('oid');  //获取URL 的参数


    const [storeData, setStoreData] = useState({ shortName: "", address: "", mainPic: "" });  //获取和商店ID 名字 图片

    // 菜单列表



    const [eatType, setEatType] = useState("eat-in");

    const [productList, setProductList] = useState([]); //购物车列表



    const [productDetail, setProductDetail] = useState([]); //购物车列表


    async function get_productDetail(UniqueProduct, num) {  //获取餐品里面的详细

        setLoadingShow(true);

        for (let i1 = 0; i1 < UniqueProduct.length; i1++) {


            let v1 = await i.post("s.php?f=get_original_goods", { storeCode: storeCode, productCode: UniqueProduct[i1].productCode }).then((v1) => {
                if (v1.data.code != 200) {


                    Modal.error({
                        title: '错误提示', width: "90%", onOk: () => {
                            // navigate("/home?oid="+oid) 
                        }, hasCancel: false, content: `【${UniqueProduct[i1].productName}】:${v1.data.message}，当前餐厅不可用,请重新选择餐厅`
                    });



                    return false;
                }

                UniqueProduct[i1].餐品详情 = v1.data.data.product;

            });//获取商品下的数据   

        }

        let tempP = [];
        for (let i = 0; i < num; i++) {  //循环复制餐品数据
            tempP.push(JSON.parse(JSON.stringify(UniqueProduct)));

        }

        setProductList(tempP); //渲染多份数据
        setLoadingShow(false);

    }

    const order_info = useRef(); //订单数据

    useEffect(() => {


        (async function () {


            //判断是否有这个订单

            order_info.current = await i.get("s.php?f=order_info&oid=" + oid, { oid: oid }); //获取订单信息
            order_info.current = order_info.current.data;


            if (!order_info.current.code) { Toast.error({ duration: 10, "content": order_info.current.msg }); return false; } //错误提示 



            let r = await i.post("s.php?f=MDL_store", { storeCode: storeCode }); //获取餐厅信息

            if (r.data.code != 200) { Toast.error({ duration: 30, content: '出现了错误' }); return false; }
            if (r.data.data.businessStatus != 1) { Toast.error({ duration: 30, content: '餐厅已经打烊了' }); return false; } //判断餐厅是否打烊

            r = r.data.data;
            setStoreData(r); //赋值




            //获取餐厅外送信息
            //  let eatType = i.post("s.php?f=MDL_eatType", { currentDaypartCode: r.currentDaypartCode, storeCode: storeCode });

            // setEatType(eatType);   


            //请求餐厅商品
            //根据时段显示商品列表   //获取餐厅所有商品 然后循环出来对比 
            let foodMenu = await i.post("s.php?f=MDL_store_menu", { currentDaypartCode: r.currentDaypartCode, storeCode: storeCode });
            if (foodMenu.data.code != 200) { Toast.error({ content: '出现了错误2' }); return false; }


            let tmpe3 = [];  //先提取出接口返回的所有的商品并且合并到一个数组 

            for (const v1 of foodMenu.data.data.menu) {

                if (v1.categories) {  // 有些商品不在productList  如饮品 和其他单点小商品 
                    for (const v2 of v1.categories) {
                        for (const v3 of v2.productList) {
                            if (v3.groupList) {  //判断商品底部还有没有选项 比如 可口可乐中杯  小杯 有的话 他里面的数据循环提取替换 
                                for (const v4 of v3.groupList) {
                                    tmpe3.push({ productCode: v4.subProductCode, productImage: v3.productImage, productName: v4.showName, priceInfo: { "eatInPrice": v4.priceInfo.eatInPrice, "eatInPriceText": v4.priceInfo.eatInPriceText } });
                                }
                            } else {
                                tmpe3.push(v3);  //直接存入商品
                            }
                        }
                    }
                }
                tmpe3.push(...v1.productList);  // 追加数组 
            }




            console.log("FF88", tmpe3);
            //把优惠券的商品全部加入订单列表

            //查找出哪一些商品没有匹配到的 商店当前时段没有 

            for (const lv1 in order_info.current.方案数据.商品列表) {
                let v9 = order_info.current.方案数据.商品列表[lv1];


                //加入商品循环
                if (v9.下单方式 = "使用券") {

                    tmpe3.push({ productCode: v9.商品ID, productImage: v9.商品图片, productName: v9.商品名称 });

                }


            }




            let UniqueProduct = []; //唯一的商品 
            tmpe3.map(async function name(v) {

                let PlanGoodsList = order_info.current.方案数据.商品列表[v.productCode];  // 赋值 看看有没有这个 商品
                if (PlanGoodsList) { //判断商品在不在列表方案里面 在就执行下面的代码 等于方案中的列表 

                    PlanGoodsList["匹配"] = "1";  //存储是否匹配到 匹配不到的 给出已售罄提示

                    let temp2 = false;
                    for (const v2 of UniqueProduct) { //商品去重
                        if (v2.productCode == v.productCode) {  //查询数组里面有没有这个餐品 有就不加入
                            temp2 = true;  //已经存在这个商品

                        }
                    }


                    if (temp2 == false) {   //不重复的加入购物列表 
                        //判断是否必选自动加入购物车    
                        UniqueProduct.push({ ...v, 餐品详情: {}, otherInfo: { 是否必选: PlanGoodsList.是否必选, 是否选中: PlanGoodsList.是否必选 } });
                    }

                }  //判断是否是在方案列表里面


            })


            //判断用券商品 然后加入商品





            //获取不到餐品提示
            if (UniqueProduct.length < 1) { Modal.error({ width: "90%", title: '当前没有获取到餐品', content: '当前没有获取到餐品,可能是未到用餐时间' }); }



            //  设置里面第一个可选的为选中状态 
            UniqueProduct.some((v10, index) => {
                if (v10.otherInfo.是否必选 !== true) {
                    v10.otherInfo.是否选中 = true;
                    return true; // 返回 true 以退出循环
                }
                return false; // 返回 false 继续下一个迭代
            });













            //查找出哪一些商品没有匹配到的 商店当前时段没有 
            let Sold_out_goods = []; //售罄商品数组
            for (const v6 in order_info.current.方案数据.商品列表) {
                let temp8 = order_info.current.方案数据.商品列表[v6];
                if (temp8.匹配 != "1") { //判断是否匹配到
                    Sold_out_goods.push(temp8.商品名称);
                }

            }


            if (Sold_out_goods.length > 0) { //提示售罄商品
                Modal.error({ width: "90%", title: '以下商品已售罄', content: <Space vertical align="start">{Sold_out_goods.map((v, index) => <Tag key={v + index} size="large" color='red'>{v}</Tag>)}</Space> });

            }










            setProductList([UniqueProduct]); //设置了商品以后就获取 餐品详情 先渲染更新数据


            get_productDetail(UniqueProduct, order_info.current.数量); //然后获取详情






        })();




    }, [])


    if (!searchParams.get('storeCode') || !searchParams.get('oid')) {
        return (<>网址不正确,请检查</>);
    }


    function handleP1(v2, comboProductsIndex) {  //套餐内商品选择

        //把这个商品下面的取消选中  
                    
 
        let t1=v2["comboProducts"][comboProductsIndex];  //获取当前的选中餐品
        
        if (v2["maxQuantity"] == 1) { //单选把其他的移出 
            for (const index in v2["comboProducts"]) { //把选中的取消}  
                v2["comboProducts"][index]["isChecked"] = 0; 
            }

            t1["isChecked"] = 1;  //引用 

        } else {
            //多选判断是否多出了选择
 
            let seleteNum=v2["comboProducts"].filter((v12)=>v12["isChecked"]=="1");

         
            if(t1["isChecked"]==1){
 //取消选中
 t1["isChecked"]=0;
            }else{

                  if(seleteNum.length>=v2["maxQuantity"]){  //判断是否超出了选择

                Toast.error({content:"超出了最大选择数量"}); 
                                return false; 
            }

            t1["isChecked"]=1; //选中

            }
          
 
        }

       
        v2["comboProducts"][comboProductsIndex]["quantity"] = 1; //数量设置为1 

 
        console.log("TTT",productList);
        
     setProductList([...productList]); 


    }




    function handleP3(oIndex, itemIndex) {  //最外部餐品选中

        console.log("LL:", oIndex, itemIndex, productList);
        if (productList[oIndex][itemIndex].otherInfo.是否必选) { return false };
        //单选 


        let t1 = [...productList];
        console.log("QQ:", t1);
        t1[oIndex].map((v) => {   //判断是必选的就要选中不能取消
            v.otherInfo.是否选中 = v.otherInfo.是否必选; //非必选的都去掉选中 
        })

        t1[oIndex][itemIndex].otherInfo.是否选中 = true;


        console.log("C88：", oIndex, itemIndex, t1);
        setProductList(t1);


    }



    function SelectItem({ oIndex, title, required }) {

        //   console.log("餐品列表", productList);

        return <div className="category1">
            <Divider margin='12px'></Divider>
            <Typography.Title heading={6} style={{ borderLeft:"5px solid #06f",paddingLeft:"5px"}}>  {title}</Typography.Title>
            <div style={{ marginTop: "10px", display: "grid", gridTemplateColumns: "1fr", gridGap: "15px" }}>
                {productList[oIndex]?.map((v2, index) => {


                    return v2.otherInfo.是否必选 == required && <div onClick={() => { 
                        handleP3(oIndex, index)
                         }} key={index + "-" + v2.productCode} style={{border:"2px solid #eee" ,filter: "grayscale(70%)",...(v2.otherInfo.是否选中 && style2), borderRadius: "5px" }}>

                        <div  style={{ display:  v2.餐品详情?.comboItems?.length>0 ?"none" : "flex" }} >
                            <img loading="lazy" style={{ width: 80, marginRight: 5 }} src={v2.productImage} alt="" />

                            <div style={{ flex: 1 }}>
                                <Text style={{ fontWeight: "bold", margin: "5px 0",fontSize:"16px", display: "block" }}>{v2.productName}</Text>
                                
                            </div>

                        </div>




              {v2.餐品详情?.customization && <div style={{padding:"0 5px"}}>
                <Item4 customization_items={v2.餐品详情?.customization.items || []}></Item4>
                <Item3 customization_grillRules={v2.餐品详情?.customization.grillRules || []}></Item3>
            </div>}


            {(v2.餐品详情?.customizationMode == 1) && (<><div className="category1">
                <Divider margin='12px'></Divider>
                <div style={style1}>


                    {v2.餐品详情?.customization?.options.map((n1, customizationIndex) => <div key={customizationIndex} onClick={() => {

                        //定制餐品取消选中
                        let t1 = productList;
                        let t2 = n1?.餐品详情["customization"]["options"][customizationIndex];
                        t2.checked = t2.checked == 1 ? 0 : 1;
                        setProductList(t1);
                    }} style={{ ...(n1.checked && style2), borderRadius: "5px" }}>

                        <img loading="lazy" style={{ width: "100%" }} src={n1.image} alt="" />

                        <div style={{ flex: 1 }}>
                            <Text style={style3}>{n1.name}</Text>
                        </div>

                    </div>)}

                </div>
            </div></>)}




<div style={{ padding:"0 8px"}} >
            {v2?.餐品详情?.comboItems?.map((v, comboItemsIndex) => {  //套餐内 餐品 主食 小食

              

                return <div style={{ marginBottom: "20px" }} key={comboItemsIndex} className="category1">

<Divider margin='12px'></Divider>
                    <Text strong type="secondary">{v.className}(数量:{v.maxQuantity})</Text>
                    <div style={style1}>


                        {v.comboProducts.map((cp2, comboProductsIndex) => {

                            let customizationShow = cp2.customization.options.length > 0 || cp2.customization.items.length > 0 || cp2.customization.grillRules != undefined; //是否显示定制按钮

                            //判断是否在隐藏商品 
                            let p1 = v2.productCode;
                            if (order_info.current.方案数据.商品列表[p1]?.显示的商品.length > 0 && !order_info.current.方案数据.商品列表[p1]?.显示的商品.includes(cp2.code)) { //判断是否在 隐藏列表里面
  
                                return false;
                            }


 
                  
 
                            return <div key={comboProductsIndex} onClick={() => { handleP1(v, comboProductsIndex); }} style={{background:cp2.isChecked &&"#fff5d7", textAlign: "center", padding:5,  borderRadius: "10px",position:"relative" }}>

                                   {cp2.isChecked==1 && <IconTickCircle style={{position:"absolute",top:"10px",color:"#ff9800",right:"10px"}} /> }


                                <div style={{ flex: 1, textAlign: "center" }}>
                                    <img loading="lazy" style={{ width: "100%", maxWidth: "60px" }} src={cp2.image} alt="" />
                                    <Text style={style3}>{cp2.name}</Text>

                                </div>


     {(customizationShow > 0) &&    <Button theme='solid' onClick={(event)=>{ event.stopPropagation();  setSlideVisible2({d:cp2,show:true}) }} style={{background:"#ffc107"}} size='small'>定制</Button> }  


                                {/* {(customizationShow > 0) && <Collapse onClick={(event)=>{  event.stopPropagation(); }}>
                                    <Collapse.Panel onClick={(event)=>{  event.stopPropagation(); }} header="定制" itemKey={cp2.name + comboProductsIndex}> 
                                        <Item2 customization_options={cp2.customization.options}></Item2> 
                                        <Item4 customization_items={cp2.customization.items} /> 
                                        <Item3 customization_grillRules={cp2.customization.grillRules || []} /> 
                                    </Collapse.Panel>
                                </Collapse>} */}

                            </div>
 
                        })}

                    </div>
                </div>;


            })}

</div>


                    </div>
                })
                }
            </div>
        </div>

    }


    function createOrder() {

        let cartData = {
            "beCode": "",
            "beType": 1,
            "cartType": "1",
            "channelCode": "03",
            "dataSource": 1,
            "daypartCode": storeData.currentDaypartCode,
            "maxPurchaseQuantity": 999,
            "orderType": 1,
            "pinId": "",
            "scenes": 0,
            "products": [],
            "storeCode": storeData.code,
            "storeName": storeData.name
        }

        //获取
        let t1 = [];  //获取选中的餐品

        let error=""; //错误信息

        productList.map((v0) => {

            v0.map(v => {   //筛选选中的餐品  

                if (v.otherInfo.是否选中) {
                    //   let t2 = JSON.parse(JSON.stringify(productDetail[v.productCode]));  //获取这个商品的详情
                    let t2 = JSON.parse(JSON.stringify(v.餐品详情));  //获取这个商品的详情

                    //未选中就去除 比如可乐 加冰 不加冰 咖啡 定制选项  (开始)-------------------------------------------
                    t2.customization?.items?.map(v4 => { v4.values = v4.values.filter(v5 => v5.checked); });  //套餐内 定制商品 去掉没选中的项目提交
                    t2.customization?.grillRules?.map(v4 => {
                        v4.grillItems.map(v5 => {
                            v5.values = v5.values.filter(v6 => v6.checked)
                            t2.customization.items.push({ ...v5 });   //加入items
                        });
                    });
                    //未选中就去除 比如可乐 加冰 不加冰 咖啡 定制选项 (结束) -------------------------------------------


                      
                    t2.comboItems?.map((v2, index) => {   //套餐内子商品 存在就遍历
 
                        t2.comboItems[index].comboProducts = v2.comboProducts.filter((v3) => {   //去掉没选中的项目


                            //套餐商品内的未选中就去除 比如可乐 加冰 不加冰 咖啡 定制选项  (开始)-------------------------------------------
                            v3.customization.items.map(v4 => { v4.values = v4.values.filter(v5 => v5.checked); });  //套餐内 定制商品 去掉没选中的项目提交
                            v3.customization.grillRules?.map(v4 => {
                                v4.grillItems.map(v5 => {
                                    v5.values = v5.values.filter(v6 => v6.checked)
                                    v3.customization.items.push({ ...v5 });   //加入items
                                });
                            });  //套餐内 咖啡 去掉没选中的项目提交
                            //套餐商品内的未选中就去除 比如可乐 加冰 不加冰 咖啡 定制选项  (结束)-------------------------------------------



                            delete v3.customization.grillRules;  //删除这个否则添加失败 
                            return v3.isChecked;

                        });// 去掉没选中的商品 

                        if(t2.comboItems[index].comboProducts.length<1){
                            error+=`${t2.comboItems[index].className}未选择；`;

                        }
                        
 
                    })


                  

                    //                 t1.push({
                    //                     ...v, ...t2, "quantity": 1, sequence: -1, "saleStatus": 1, "type": 2, likeCount: 0, rightCardType: 0, alcPrice: 0, originalPrice: 0, cardType
                    // :0,operationType:0}); // 合并加入购物车 并且去掉选项里面的餐品

                    t1.push({
                        "addonInvalidTime": 0,
                        "bu": 0,
                        "calculatePrice": false,
                        "cardId": "",
                        "cardType": 0,
                        "code": t2.code,
                        "couponCode": "",
                        "couponId": "",
                        "customization": t2.customization || {},
                        "customizationMode": t2.customizationMode,
                        "dayOfWeek": "",
                        "desc": "",
                        "failType": 0,
                        "fistSelect": true,
                        "image": "",
                        "isCouponEnable": 0,
                        "comboItems": t2.comboItems || [],
                        "isPromotion": 0,
                        "isSellOut": false,
                        "likeCount": 0,
                        "longName": "",
                        "name": "",
                        "operationType": 0,
                        "packingFeePrice": 0,
                        "priceStatus": 0,
                        "promotionId": "",
                        "quantity": 1,
                        "realPackingFeeTotalPrice": 0,
                        "saleStatus": 1,
                        "sequence": -1,
                        "showSelectBtn": false,
                        "type": 1
                    });

                }

            })

        })

        if(error){ //判断餐品是否未选择
            Modal.error({width:"90%", title: '你还有餐品未选择', content: error }); 
         return false;
         
        }




        //选中的数据加入购物车

        if (t1.length < 1) { Toast.info("请选中餐品"); return false; }

        cartData.products = t1;


        console.log("下单提交数据列表", cartData);
        setLoadingShow(true);

 

        i.post("s.php?f=create_order", { oid: oid, eatType: eatType, payload: cartData }).then((r) => {
            setLoadingShow(false);
            if (r["data"]["code"] == 1) {

                
                navigate("/TakeFood?oid=" + oid); //跳转取餐页

            } else {

                Modal.error({ width: "90%", title: '下单出现错误', content: r["data"]["msg"] });


            }

        });


    }


    function Item2({ customization_options }) { // 更多按钮里面 主食定制

        return <div style={style1}>{customization_options.map((v4, optionsIndex) => {  //主食定制
            return v4.soldOut != true && <div key={optionsIndex} onClick={() => {

                v4.checked = v4.checked == 1 ? 0 : 1;
                //  setProductDetail({ ...productDetail });

                setProductList([...productList]);
                //   console.log("套餐内定制餐品数据", t1);

            }} style={{  border: "2px solid #eee", ...(v4.checked && style2), borderRadius: "5px" }}>

                <img loading="lazy" style={{ width: "100%" }} src={v4.image} alt="" />

                <div style={{ flex: 1 }}>
                    <Text style={style3}>{v4.name}</Text>
                </div>

            </div>;


        })}</div>


    }



    function Item3({ customization_grillRules }) {   // 咖啡热饮定制 

        return customization_grillRules.map((v4, itemsIndex) => {
            return v4.grillItems.map((v4_1, v4_1Index) => {
                //已卖完的不显示
                return v4_1.soldOut != true && <div key={v4_1Index}>
                    <Divider align='center'  >
                        {v4_1.name}
                    </Divider>

                    <div style={style1}>
                        {v4_1.values.map((v5, v5Index) => <div key={v5Index} onClick={() => {
                            //单选  //套餐内子商品筛选  其他的取消选中 
                            v4_1.values.map(v6 => { v6.checked = 0; }) //单选都重置为0
                            v5.checked = v5.checked == 1 ? 0 : 1; //v5也是引用地址 
                            setProductList([...productList]);
                            //  setProductDetail({ ...productDetail });

                        }} style={{ ...(v5.checked && style2), borderRadius: "5px" }}>
                            <div style={{ flex: 1 }}>
                                <Text style={style3}>{v5.name}</Text>
                            </div>

                        </div>)}
                    </div>


                </div>;


            })

        })



    }



    function Item4({ customization_items }) { // 用于 可乐 加冰 不加冰 定制

        return customization_items.map((v4, itemsIndex) => {  //可乐加冰不加冰定制
            return v4.soldOut != true && <div key={itemsIndex}>
                <Divider align='center'  >
                    {v4.name}
                </Divider>
                <div style={style1}>
                    {v4.values.map((v5, v5Index) => <div key={v5Index} onClick={() => {
                        //单选  //套餐内子商品筛选  其他的取消选中 
                        v4.values.map(v6 => { v6.checked = 0; }) //单选都重置为0
                        v5.checked = v5.checked == 1 ? 0 : 1; //v5也是引用地址  
                        setProductList([...productList]);
                        //  setProductDetail({ ...productDetail });

                    }} style={{ ...(v5.checked && style2), borderRadius: "5px" }}>
                        <div style={{ flex: 1 }}>
                            <Text style={style3}>{v5.name}</Text>
                        </div>
                    </div>)}
                </div>
            </div>;

        })



    }


    return (<>
        <div style={{ padding: "10px" }} >

            <Card

                cover={
                    <img
                        alt="example"
                        src={storeData.mainPic}
                    />
                }

            >
                <Meta
                    title={storeData.shortName}
                    description={storeData.address}
                    avatar={
                        <Avatar
                            alt='Card meta img'
                            size="default"
                            src='/img/mdllogo.svg'
                        />
                    }
                />
            </Card>


            <RadioGroup onChange={(e) => { setEatType(e.target.value) }} style={{ marginTop: "10px", display: "flex", justifyContent: "center" }} type='card' value={eatType} name="radio-group-card">


                <Radio value="eat-in">
                    堂食
                </Radio>
                {/* <Radio value="teke-in-store">
                    外带
                </Radio> */}


            </RadioGroup>




            {productList.map((v, oIndex) => <div key={oIndex}>
                 <SelectItem oIndex={oIndex} title="已包含餐品" required={true}></SelectItem>
            <SelectItem oIndex={oIndex} title="请选择餐品(1样)" required={false}></SelectItem>
            
            </div>)}







            <div style={{ height: "60px" }}></div>

            <Row style={{ position: "fixed", display: productList.length < 1 && "none", left: 0, bottom: 0, width: "100%", padding: "10px", background: "#f5f5f5" }}>
                <Col span={6}><div className="col-content">  <Button block theme='borderless' type='primary'>返回</Button></div></Col>
                <Col span={18}><div className="col-content"> <Button block onClick={() => {

                    Modal.confirm({ width: "90%", title: '请确认你所选择的餐厅', onOk: createOrder, content: <div style={{ marginTop: "20px" }}><b>{storeData.shortName}</b><br />{storeData.address}</div> });

                }} theme='solid' type='primary'>确认下单</Button></div></Col>
            </Row>







        </div>


 



        <SideSheet placement="bottom" height="85vh" title="定制" visible={SlideVisible2.show} onCancel={() => setSlideVisible2({...SlideVisible2,show: false })} >
       
       
                                        <Item2 customization_options={SlideVisible2.d.customization?.options}></Item2> 
                                        <Item4 customization_items={SlideVisible2.d.customization?.items} /> 
                                        <Item3 customization_grillRules={SlideVisible2.d.customization?.grillRules || []} /> 
        </SideSheet>

    </>);



}

