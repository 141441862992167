
import { useAppContext, Extract_sid } from "@/context/context";
import { useState, useRef, useEffect } from "react";
import { Tooltip, Space, Popconfirm, Tag, Dropdown, SideSheet, Form, Modal, Button, Toast, Divider } from '@douyinfe/semi-ui';
import { IconHelpCircle } from "@douyinfe/semi-icons";
import i from "@/api/axiosInstance";


export default function AddCouponCode({ sync_function, classify, SlideVisible, setSlideVisible, couponID }) {



    const formApi2 = useRef(); //获取formapi 



    // if (couponID) {  //判断是否有指定ID
    //     setTimeout(() => {
    //         formApi2.current.setValues({ 优惠券ID: couponID });
    //     }, 200);
    // }

    useEffect(() => {





    }, [])


    async function handleForm() { //处理表单提交

        //表单 验证
        formApi2.current.validate().then(async values => {

            //添加syncList 循环同步权益

            let syncList = [];  //循环同步


            let e_sid = Extract_sid(values.sid列表);
            formApi2.current.setValue("sid列表", e_sid); 
            for (const v of e_sid.split("\n")) {    //添加数组
                syncList.push({ 分类: classify[values.分类], 备注: values.备注, sid: v.trim() });
            }

            console.log(8989);
 
     
            let r = await sync_function({ syncList: syncList });  //同步优惠券


        }).catch((error)=>{ console.log(error);});


    }

    function showTip() {
        
        Modal.success({ title: '分类说明(导入以下权益)', content: <>  {classify.map((v, index) => <div key={index} style={{ borderBottom: "1px solid #eee", padding: "10px 0" }}>{v.分类名称}：{v.权益列表.map(v1 => <div style={{ margin: "5px", color: "#999" }}> <Tag color="orange">{v1[2]}</Tag> {v1[1]}({v1[0]}) </div>)}</div>)}</> });
    }


    return (<SideSheet motion={false} width="700px" title="导入权益账号" visible={SlideVisible} headerStyle={{ borderBottom: '1px solid var(--semi-color-border)' }} onCancel={() => setSlideVisible(false)} >

        <Form getFormApi={formApi => formApi2.current = formApi}   >

            <Form.Select field="分类" label={{
                text: '分类',
                extra: <IconHelpCircle onClick={showTip} style={{ color: 'var(--semi-color-text-2)' }} />
            }} placeholder="请选择导入的权益分类" style={{ width: "100%" }} rules={[{ required: true, message: '不能为空' }]}    >

                {classify.map((v, index) => <Form.Select.Option key={index} value={index}>{v.分类名称}</Form.Select.Option>)}
            </Form.Select>



            <Form.Input field='备注' />

            <Form.TextArea field='sid列表' rules={[{ required: true, message: '不能为空' }]} placeholder='批量导入sid的券 一行一个sid ' />

            <Space style={{ width: "100%", marginTop: "40px", justifyContent: "flex-end", padding: "15px 0", borderTop: "1px solid #eee" }}>

                <Button theme='solid' type='primary' onClick={handleForm} style={{ width: 140 }}>确认</Button> </Space>


        </Form>

    </SideSheet>);


}