
import { Table, Space, Image, Popconfirm, Tag, Form, Modal, Button, Toast,Divider } from '@douyinfe/semi-ui'; 
import { useState,useRef, useEffect } from "react";
import i from "@/api/axiosInstance"; 

import { useAppContext } from "@/context/context";

export default function DiscountCoupon() {
    

    const { setLoadingShow } = useAppContext();  //加载层隐藏或者显示 

    const columns = [
        {
            title: '优惠券ID',
            dataIndex: '优惠券ID',
        },
        {
            title: '优惠券名称',
            dataIndex: '优惠券名称',
        },
       
      
        {
            title: 'promotionId(促销ID)',
            dataIndex: 'promotionId',
        },
        
        {
            title: 'BE类型',
            dataIndex: 'BE类型',
            render: (text, record, index) => {

                text=JSON.parse(text);

                return (<Space>{text.map((v) => (<Tag key={v[1]} color="light-blue">{v[1]}</Tag>))}</Space>)

            }
        },
        {
            title: '订单类型',
            dataIndex: '订单类型',
            render: (text, record, index) => {

                text = JSON.parse(text);

                return (<Space>{text.map((v) => (<Tag key={v} color="amber">{v}</Tag>))}</Space>)

            }
        },
        {
            title: '可用时段',
            dataIndex: '可用时段',
            render: (text, record, index) => {
                

                return (<>{record.可用起始时间}-{record.可用结束时间}</>)
 
            }
        }, {
            title: '优惠券更新时间',
            dataIndex: '优惠券更新时间',
        },
        {
            title: '操作',
            dataIndex: 'owner',
            render: (text, row, index) => {
                return (<Space> 
                    <Popconfirm
                        title="是否要删除"
                        onConfirm={() => { deleteDate(row.id) }}
                    > 
                    <Button size="small" type='danger'   theme='solid'>删除</Button> 
                    </Popconfirm>
                </Space>);
            },
        }

    ];
      

    async function deleteDate(id) {
 
        const r = await i.post("s.php?f=delete_data", { id: id, b: "mdl_coupon" });
        Toast.info({ "content": r.data.msg }); 
        getList(); 
    }
 

 
    const formApi1 = useRef(); //获取搜索表单1 API

    const formApi2 = useRef(); //获取formapi



    const [data, setData] = useState([]);
 
async function getList(){  
    console.log(formApi1.current.getValues()); 
    const r1 = await i.post("s.php?f=get_couponList", formApi1.current.getValues()); 
    setData(r1.data.data);
   
}

 
useEffect(()=>{


    getList();


},[])


 
    async function SyncCoupon(values){

        if(values.sid){
  


            setLoadingShow(true);//显示加载层

            const r =await i.post("s.php?f=get_discount_coupon", { sid: values.sid });


            setLoadingShow(false);//关闭加载层
            if (r.data.code==1){
                formApi2.current.setValues({ sid: "" }); //设置值 

                
                Modal.info({
                    title: '操作成功', hasCancel: false, content: <><p style={{ fontSize: "16px",lineHeight:"1.5" }}>  
                    券成功添加数量:{r.data.succeed} <br />
                        重复券码数量:{r.data.repetition}
                        <br />
                        商品添加数量:{r.data.goods_Successful}
<br />

{r.data.info.map(v=> <Tag color="red">{v}</Tag>)}
                       
                        
                    </p></>
                });


            }else{
 
                Toast.info({ content: '操作失败,接口可能出现错误' });

            }
           
          

         

        }else{

            Toast.info({ content: 'sid不能为空'});

        }
      

    }



    return (<>
      <Space>
 
            <Form onSubmit={getList} getFormApi={formApi => formApi1.current = formApi} layout='horizontal' >
           
            <Form.Input
            placeholder="输入关键字搜索"
                field='key'
                label={{ 
                    text: '搜索' 
                }}
                style={{ width: 176 }}
            />

               <div style={{ display: 'flex', alignItems: 'flex-end' }}>
                    <Button type='primary' htmlType="submit" theme='solid' style={{   marginRight: 4 }}>搜索</Button>
                    <Button onClick={()=>{ setTimeout(() => {  getList();  }, 200); }} htmlType="reset">重置</Button>
                </div>
             
  
        </Form>






            <Form getFormApi={formApi => formApi2.current = formApi} style={{ marginLeft: "60px" }} onSubmit={SyncCoupon} layout='horizontal' >

                <Form.Input
                    placeholder="账户的sid"
                    field='sid'
                    label={{
                        text: 'sid'

                    }}
                    style={{ width: 176 }} 
                    
                />

                <div style={{ display: 'flex', alignItems: 'flex-end' }}>
                    <Button type='primary' htmlType='submit' theme='solid' style={{ marginRight: 4 }}>同步优惠券和商品</Button>
                    
                </div>


            </Form>

        
    </Space>
      <Divider margin='12px'> 
            </Divider>
        <Table columns={columns} scroll={{ y: window.innerHeight - 285 }} dataSource={data} pagination={false} />
    </>);

}