
import './App.css';
import Home from "./pages/home/home";
import { HashRouter as Router, Route, Routes, Link, Navigate, useNavigate } from 'react-router-dom';
import OnlineOrder from "./pages/home/OnlineOrder";
import Login from "./pages/manage/login";
import TakeFood from "./pages/home/TakeFood";
import OrderDetail from "./pages/home/OrderDetail";
import ManageHome from "./pages/manage/manageHome";

import { AppProvider, useAppContext } from "./context/context";

//定义路由
const PrivateRoute = ({ element }) => {  //判断是否有token

  return localStorage.t && Date.now() / 1000 < localStorage.et ? element : <Navigate to="/m_login" />;

};





function App() {




  return (<>
    <AppProvider>

    


        <Router>

          <Routes>
            {[
              { path: "/", element: <Home /> },
              { path: "/m_login", element: <Login /> },
              { path: "/home", element: <Home />, },
              { path: "/OnlineOrder", element: <OnlineOrder /> },
              { path: "/home", element: <Home />, },
            { path: "/OrderDetail", element: <OrderDetail />, }, 
            { path: "/TakeFood", element: <TakeFood />, }, 
              { path: "/ManageHome/*", element: <ManageHome />, requiresAuth: true },
              
            ].map((r) => {

              //渲染理由 判断token  
              return (<Route key={r.path} path={r.path} element={r.requiresAuth ? <PrivateRoute element={r.element} /> : r.element} />);
 

            })}

          </Routes>

 
        </Router>
   
    </AppProvider>
  </>);
}

export default App;
