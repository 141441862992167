
import { Table, Space, Popconfirm, Tag, InputNumber, Dropdown, SideSheet, Form, Modal, Button, Toast, Divider } from '@douyinfe/semi-ui';
import { useState, useRef, useEffect } from "react";
import { IconTreeTriangleDown } from "@douyinfe/semi-icons";
import i from "@/api/axiosInstance";
import { useAppContext } from "@/context/context";
import { format, subDays } from 'date-fns';

import AddAccount from "@/components/AddAccount";

export default function NotifyList() {



    const { setLoadingShow } = useAppContext();  //加载层隐藏或者显示 

    const columns = [

        {
            title: '异常信息', 
            dataIndex: '通知文字' 
        },
        {
            title: 'sid', 
            width:210,
            dataIndex: 'sid' 
        }, 
        {
            title: '通知时间',
            width:180,
            dataIndex: '通知时间' 
        }
 
    
    ];


  

  
 
    const formApi1 = useRef(); //获取搜索表单1 API
 

    const [data, setData] = useState([]);

    let limit = 30; //分页页数
    async function getList(page) {

        page = page ? page : currentPage;

        const r1 = await i.post(`s.php?f=get_notify_list&type=1&limit=${limit}&page=${page}`, formApi1.current.getValues());


        setData(r1.data);
      
        

    }
   
    useEffect(() => {

        formApi1.current.setValues({ 订单创建时间:[new Date(),new Date()]});

 
        getList(1);  //获取第一页商品

 

   
 

    }, [])


    const [SlideVisible, setSlideVisible] = useState(false); //侧边栏显示或隐藏


 

    const [currentPage, setPage] = useState(1);

    const [selectedRows, setSelectedRows]=useState([]);





function setCreateDate(n){

    console.log(n);
    
    const d1 = subDays(new Date(), n);
    formApi1.current.setValues({通知时间:[d1,new Date()]});

}


    return (<>
        <Space> 
            <Form getFormApi={formApi => formApi1.current = formApi} layout='horizontal' >

                <Form.Input
                    placeholder="输入关键字搜索"
                    field='key'
                    label={{
                        text: '搜索'

                    }}
                    style={{ width: 176 }}
                />
             

            
                <Form.DatePicker field="通知时间" style={{ width: 260 }} onChange={(date) => { console.log(date); }} type="dateRange" label={<Space>通知时间<Tag color="white" onClick={()=>{ setCreateDate(0) }} >今日</Tag><Tag color="white" onClick={()=>{ setCreateDate(1)}} >昨天</Tag><Tag color="white" onClick={()=>{ setCreateDate(3)}}>3天</Tag> <Tag color="white" onClick={()=>{ setCreateDate(7)}}>7天</Tag></Space>} />





                <Space style={{ display: 'flex', alignItems: 'flex-end' }}>
                    <Button type='primary' onClick={() => getList(1)} theme='solid'>搜索</Button>
                    <Button style={{marginRight:"50px"}} onClick={() => { setTimeout(() => { getList(1); }, 200); }} htmlType="reset">重置</Button>

                    {/* <Button type='primary'  onClick={() => setSlideVisible(true)} theme='solid' >导入</Button>
 */}

  
                    {/* <Popconfirm
                        title="是否要删除"
                        onConfirm={() => { 
                            let t1 = [];
                            for (const v of selectedRows) {
                                t1.push(v.id);
                            }  
                            deleteDate(t1) ; 
                        }}
                    >
                        <Button type='danger' theme='solid' >批量删除</Button>

                    </Popconfirm>
                */}

                </Space>



            </Form>





        </Space>
        <Divider margin='12px'>
        </Divider>
        <Table columns={columns} rowSelection={{ 
            onChange: (selectedRowKeys, selectedRows) => { 
                setSelectedRows(selectedRows);
            },
        }} dataSource={data.data} rowKey="id" pagination={{
            currentPage,
            pageSize: limit,
            total: data.count,
            onPageChange: (page) => { setPage(page); getList(page); },
            }} scroll={{ y: window.innerHeight-285 }} />


 
       


    </>);

}