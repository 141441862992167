
import { Table, Space, Popconfirm, Progress, Tag, Dropdown, SideSheet, Form, Modal, Button, Toast, Divider } from '@douyinfe/semi-ui';
import { useState, useRef, useEffect } from "react";
import { IconTreeTriangleDown } from "@douyinfe/semi-icons";
import i from "@/api/axiosInstance";

import AddCouponCode from "@/components/AddCouponCode";
import { useAppContext } from "@/context/context";

export default function DiscountCoupon() {
 

    const { setLoadingShow } = useAppContext();  //加载层隐藏或者显示 

   const [couponID,setCouponID]=useState(false);//指定选中的下拉框

    const columns = [
        {
            title: '优惠券ID',
            dataIndex: '优惠券ID',
        },
        {
            title: '优惠券名称',
            dataIndex: '优惠券名称',
        }, 
        {
            title: '同步时间',
            dataIndex: '最新同步时间',
        },
        {
            title: '异常',
            dataIndex: '异常'
        },
        {
            title: '未使用',
            render: (text, record, index) => { 
                return (record.总数量 - record.异常 - record.已使用); 
            }
        },
        {
            title: '已使用',
            dataIndex: '已使用'
        },
        {
            title: '总数量',
            dataIndex: '总数量'
        },
        {
            title: '已使用数量', 
            render: (text, record, index) => {

              
                return (<Progress percent={100-((record.总数量 - record.异常 - record.已使用) / record.总数量) * 100} showInfo  />);
            }
        },
 
        {
            title: '操作',
            dataIndex: 'owner',
            render: (text, row, index) => {

              
                return (<Space>


                    <Button size="small" onClick={() => {  
                        sync_coupon({ syncList: row.sid列表})
                     }}
                      theme='solid'>同步</Button> 
                    <Dropdown
                        render={
                            <Dropdown.Menu>
                                <Dropdown.Item onClick={() => { setCouponID(row.优惠券ID); setSlideVisible(true); }}>导入</Dropdown.Item>
                                <Dropdown.Item onClick={() => { coupon_status1(row, "出库") }}>出库</Dropdown.Item>
                                <Dropdown.Item onClick={() => { coupon_status1(row, "正常") }}>入库</Dropdown.Item>
                                <Popconfirm
                                    title="是否要删除"
                                    onConfirm={() => { deleteDate(row.sid列表) }}
                                >
                                    <Dropdown.Item >删除</Dropdown.Item>
                                </Popconfirm>
                            </Dropdown.Menu>
                        } trigger="hover" position="bottomRight">




                        <Button size="small" theme="light" type="primary" icon={<IconTreeTriangleDown />}></Button>
                    </Dropdown>
                </Space>);
            },
        }
    ];



    function coupon_status1(row, value) {


        //批量设置出库或者入库
         
       let list=row.sid列表.map((v)=>{ 
          return {...v, field:"入库状态", value:value}
        })



        i.post("s.php?f=coupon_stutas1", { list: list }).then(r => {




            Toast.info({ "content": r.data.msg });

            if (r.data.code == 1) { getList(); }

        })

    }


    async function deleteDate(list) {

        const r = await i.post("s.php?f=delete_coupon", {list:list, b: "mdl_coupon_of_sid" });
        Toast.info({ "content": r.data.msg });
        getList();
    }




    const formApi1 = useRef(); //获取搜索表单1 API

    const formApi2 = useRef(); //获取formapi



    const [data, setData] = useState([]);

    let limit = 30; //分页页数
    async function getList(page) {

        page = page ? page : currentPage;

        const r1 = await i.post(`s.php?f=get_coupon3&limit=${limit}&page=${page}`, formApi1.current.getValues());


        setData(r1.data);

    } 


    useEffect(() => {


        getList(1);  //获取第一页商品

 

    }, [])


    const [SlideVisible, setSlideVisible] = useState(false); //侧边栏显示或隐藏

   async function handleForm() { //处理表单提交

        //表单 验证
        formApi2.current.validate().then(async values => {

            console.log(values);

            //添加syncList 循环同步优惠券


         let syncList = [];  //循环同步

            for (const v of values.sid列表.split("\n")) {    //添加数组
                syncList.push({ 方案ID: values.方案ID, 备注: v.备注, sid: v.trim(), 优惠券ID: values.优惠券ID });
            }


            let r = await sync_coupon({ syncList: syncList });  //同步优惠券
      

        }) 



    }


  async function sync_coupon(values){  //同步优惠券 

    setLoadingShow(true);

  let r=await i.post("s.php?f=sync_coupon1", { ...values }); 
    
      setLoadingShow(false);
     
      Modal.info({
          title: '操作成功', hasCancel: false, width: "500px", content: <><p style={{ fontSize: "16px", lineHeight: "1.5" }}>
              新增券数量:{r.data.succeed} <br /> 
              更新券数量:{r.data.repetition} <br /> 
              {r.data.error > 0 && <Tag>向数据库添加数据时出错,数量:{r.data.error}</Tag>}
              {r.data.info.map((v, index) => <Tag key={index} color="red">{v}</Tag>)}
          </p></>
      });

      getList();


      return r;


 }




    const [currentPage, setPage] = useState(1);

    const [selectedRows, setSelectedRows]=useState([]);
    return (<>
        <Space>
 
            <Form getFormApi={formApi => formApi1.current = formApi} layout='horizontal' >

                <Form.Input
                    placeholder="输入关键字搜索"
                    field='key'
                    label={{
                        text: '搜索'

                    }}
                    style={{ width: 176 }}
                />

                <Space style={{ display: 'flex', alignItems: 'flex-end' }}>
                    <Button type='primary' onClick={() => getList(1)} theme='solid'>搜索</Button>
                    <Button onClick={() => { setTimeout(() => { getList(1); }, 200); }} htmlType="reset">重置</Button>

               


                </Space>



            </Form>





        </Space>
        <Divider margin='12px'>
        </Divider>
        <Table columns={columns} scroll={{ y: window.innerHeight - 285 }} dataSource={data.data} rowKey="id" pagination={{
            currentPage,
            pageSize: limit,
            total: data.count,
            onPageChange: (page) => { setPage(page); getList(page); },
        }} />




         

        <AddCouponCode SlideVisible={SlideVisible} setSlideVisible={setSlideVisible} couponID={couponID} sync_coupon={sync_coupon}></AddCouponCode>

    </>);

}