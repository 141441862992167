import { useState, useEffect, useRef } from "react";

import i from "@/api/axiosInstance";
import { useSearchParams } from 'react-router-dom';
import { Toast, Tag } from "@douyinfe/semi-ui";


export default function OrderDetail() {

    const [searchParams] = useSearchParams();

    const orderId = searchParams.get('orderId');  //获取URL 的参数

    const sid = searchParams.get('sid');  //获取URL 的参数
 
    const [orderInfo, setorderInfo] = useState({});

    useEffect(() => {


        (async function () {

            //判断是否有这个订单
           await orderDetail();
         

        })();

    }, [])


 
async function orderDetail(){

    let t1 = await i.post("s.php?f=MDL_orderDetail", { sid: sid, orderId: orderId }); //获取订单信息

    if (t1["data"]["code"] == 0) { Toast.info({ "content": t1["data"]["msg"] }); }


    setorderInfo(t1["data"]["data"]);


    if (t1["data"]["data"]["orderStatus"]=="待支付"){

        setTimeout(() => {
            orderDetail();
        }, 2000);

    }


}
  


  
    // < span className = "hz1" > 取餐码</ >
    return <>  <div className="c fd1">
        <div className="c1">
            <div className="c2">
                <p className="c3"><b>{orderInfo.pickupCode || orderInfo.orderStatus}</b> <span className="hz1">取餐码</span></p>
                <p className="c4"><i className="layui-icon layui-icon-ok-circle"></i> 订单已准备完毕，喜欢您再来</p>
            </div>
        </div>
        <div className="c5" style={{ position: 'relative' }}>
            <img src="/img/q1.png" alt="" />
            <img
                src="/img/qrcode.png"
                style={{
                    width: '60px',
                    position: 'absolute',
                    right: '20px',
                    top: 0,
                    bottom: 0,
                    margin: 'auto'
                }}
                alt=""
            />
        </div>

        <div className="c6">
            <div className="h11">
                <div className="a1">
                    <img
                        src="/img/m.png"
                        style={{ width: '20px', marginRight: '5px' }}
                        alt=""
                    /> {orderInfo.storeName} <i className="layui-icon layui-icon-right"></i>
                </div>
                <div className="a2" style={{ fontSize:14}}> 收藏</div>
            </div>

            {orderInfo.orderProductList?.map((v, index) => (
                <div key={v.id+index} className="h2">
                    <div className="b1"><img src={v.productImage} alt="" /></div>
                    <div className="b2">
                        <p className="bt">{v.productName}</p>
 
                        {v.comboItemList.map((v3) => <p key={v3.productCode} className="b3">{v3.name}×{v3.quantity}</p>)}
                       


                        <p className="b4">
                            <span>{v[3]}</span>
                            <span className="jg">{v[4]}</span>
                        </p>
                    </div>
                </div>
            ))}

            {/* <div className="x1">
                <span style={{ fontSize: '14px' }}>商品小计</span>
                <span style={{ float: 'right' }}>{orderInfo.productTotalPrice}</span>
            </div>

            <div className="x2">
                实付 <span>{orderInfo.realTotalAmount}</span>
            </div> */}
        </div>

        <div className="c8 c6">
            <span className="c81">发票信息</span>
        </div>

        <div className="c6" style={{ marginBottom: '50px' }}>
            <p className="bt1">订单信息</p>
            <div className="item1">
                <div className="d1">订单号</div>
                <div className="d2">{orderInfo.orderId}</div>
            </div>
            <div className="item1">
                <div className="d1">就餐方式</div>
                <div className="d2">{orderInfo.eatTypeName}</div>
            </div>
            <div className="item1">
                <div className="d1">取餐码</div>
                <div className="d2">{orderInfo.pickupCode}</div>
            </div>
            <div className="item1">
                <div className="d1">餐具需求</div>
                <div className="d2">{orderInfo.tablewareInfo}</div>
            </div>
            <div className="item1">
                <div className="d1">支付方式</div>
                <div className="d2">{orderInfo.paymentChannelLabel ||""}</div>
            </div>
            <div className="item1">
                <div className="d1">下单时间</div>
                <div className="d2">{orderInfo.createTime}</div>
            </div>
            <div className="item1">
                <div className="d1">支付时间</div>
                <div className="d2">{orderInfo.saleTime||""}</div>
            </div>
        </div>
    </div>

        <div className="dxf">
            <a href="">联系客服</a>
            <a className="hs" href="">再来一单</a>
        </div>

        <div style={{ height: '50px' }}></div></>;

}