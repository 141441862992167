
import { Table, Space, Popconfirm, Tag, Tooltip, SideSheet, Form, Modal, Button, Toast, Divider } from '@douyinfe/semi-ui';
import { useState, useRef, useEffect } from "react";
import { IconHelpCircle } from "@douyinfe/semi-icons";
import i from "@/api/axiosInstance";

import { useAppContext } from "@/context/context";

export default function DiscountCoupon() {



    const { setLoadingShow } = useAppContext();  //加载层隐藏或者显示 

    const columns = [
        {
            title: '优惠券ID',
            dataIndex: '优惠券ID',
            render: (text, record, index) => {
            return (<><div>{text}</div></>);
        }
        },
        {
            title: '优惠券券码',
            dataIndex: '优惠券券码',
            render: (text, record, index) => {
                return (<>{text}  {record.置顶 == 1 && <Tag color="light-blue" >已置顶</Tag>} </>)
        }
        },
        {
            title: '优惠券名称',
            dataIndex: '优惠券名称',
            render: (text, record, index) => {
                return (<><div>{text}</div>  {record.方案编码 && <Tag color='red' >限定方案：{record.方案编码} </Tag>} </>);
            }
        }, 

        {
            title: 'sid',
            dataIndex: 'sid',
            render: (text, record, index) => {
                return (<>{text} <Tag color="green" > {record.手机号} </Tag></>)

            }
        },


        {
            title: '券码状态',
            dataIndex: '券码状态',
            render: (text, record, index) => { 
                return (<>{text == "异常" ? <Tooltip content={record.异常通知}><Tag color="red" >{text} <IconHelpCircle style={{ marginLeft: 5 }} /></Tag>   </Tooltip> : <Tag color="green" >{text}</Tag>}</>)

            }
        }, 
     
        {
            title: '到期时间',
            width:110,
            dataIndex: '可用结束时间'
        },
        
        {
            title: '今日可用',
            dataIndex: '今日可用次数'
        },
        {
            title: '剩余数量',
            dataIndex: '剩余数量'
        },
        {
            title: '总数量',
            dataIndex: '总数量'
        },
        {
            title: '操作',
            dataIndex: 'owner',
            render: (text, row, index) => {

                return (<Space> 
                    <Button size="small" onClick={() => {    }}  theme='solid'>订单明细</Button>  
                </Space>);
            },
        }
    ];


 


    const formApi1 = useRef(); //获取搜索表单1 API
 



    const [data, setData] = useState([]);

    let limit = 30; //分页页数
    async function getList(page) {

        page = page ? page : currentPage;

 

        let date = { ...formApi1.current.getValues() };
        //date.到期时间[0]
         
        const r1 = await i.post(`s.php?f=get_couponCodeList&limit=${limit}&page=${page}`, date);   //获取券码
 
        setData(r1.data);

    }
 
    useEffect(() => {



        getList(1);  //获取第一页商品


    

    }, [])

 

    function change_status1(field,state) { // value 入库或者出库状态


        let list = [];
        if (selectedRows.length < 1) { Toast.info({ content: "最少需要选中一条数据" }); return false; }
        for (const v of selectedRows) {
            list.push({id:v.id, sid: v.sid, 优惠券ID: v.优惠券ID,field:field, value: state });
        }
  
    i.post("s.php?f=coupon_stutas1&where=id", { list: list }).then(r => {
 
            Toast.info({ "content": r.data.msg });

            if (r.data.code == 1) { getList(); }

        })

    }

 
 


    const [currentPage, setPage] = useState(1);

    const [selectedRows, setSelectedRows]=useState([]);
    return (<>
        <Space>
 
            <Form getFormApi={formApi => formApi1.current = formApi} layout='horizontal' >

                <Form.Input
                    placeholder="输入关键字搜索"
                    field='key'
                    label={{
                        text: '搜索'

                    }}
                    style={{ width: 176 }}
                />

                <Form.Select field="券码状态" >
                    <Form.Select.Option value="0">全部</Form.Select.Option>
                    <Form.Select.Option value="正常">正常</Form.Select.Option>
                    <Form.Select.Option value="异常">异常</Form.Select.Option>
                    <Form.Select.Option value="已使用">已使用</Form.Select.Option>
                    <Form.Select.Option value="未同步">未同步</Form.Select.Option>
                </Form.Select>

                <Form.DatePicker field="到期时间" style={{width:260}} onChange={(date)=>{ console.log(date);}} type="dateRange" label='到期时间筛选'    />



                <Space style={{ display: 'flex', alignItems: 'flex-end' }}>
                    <Button type='primary' onClick={() => getList(1)} theme='solid'>搜索</Button>
                    <Button onClick={() => { setTimeout(() => { getList(1); }, 200); }} htmlType="reset">重置</Button>
 

                    <Button type='warning' onClick={() => { change_status1("置顶", "1") }} theme="solid" >置顶(优先使用)</Button>
                    <Button type='warning' onClick={() => { change_status1("置顶", "0") }} theme="solid" >取消置顶</Button>
                
                </Space>



            </Form>





        </Space>
        <Divider margin='12px'>
        </Divider>
        <Table columns={columns} scroll={{ y: window.innerHeight - 285 }} rowSelection={{ 
            onChange: (selectedRowKeys, selectedRows) => { 
                setSelectedRows(selectedRows);
            },
        }} dataSource={data.data} rowKey="id" pagination={{
            currentPage,
            pageSize: limit,
            total: data.count,
            onPageChange: (page) => { setPage(page); getList(page); },
        }} />



    </>);

}