import { useState, useEffect, useRef } from "react";

import i from "@/api/axiosInstance";
import { IconSpin,IconChevronRight } from '@douyinfe/semi-icons';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { Toast, Tag,Typography } from "@douyinfe/semi-ui";


export default function TakeFood() {

    const { Text } = Typography;
    const [searchParams] = useSearchParams();

    const oid = searchParams.get('oid');  //获取URL 的参数

    const navigate = useNavigate(); 
    const timer1 = useRef(0);

    const [FInfo, setFInfo] = useState({ storeName: "正在加载...", order_list: [] });

    useEffect(() => {


        (async function () {

            //判断是否有这个订单

    
            await TakeFoodList();
            timer1.current = setInterval(TakeFoodList,3000); 
        })();

        return () => {  //卸载清除定时器
            if (timer1.current) {
                clearInterval(timer1.current);
            }
        };

    }, [])


    async function TakeFoodList(){

        let t1 = await i.post("s.php?f=TakeFood", { oid: oid }); //获取订单信息

        if (t1["data"]["code"] == 0) { Toast.info({ "content": t1["data"]["msg"] }); } 

        setFInfo(t1["data"]);

        
           //没有请求的数据就取消定时器

        let temp1 = t1["data"].order_list.filter((v8) => v8.麦当劳订单状态!="已完成");
      
       if(temp1.length==0){  clearInterval(timer1.current);}

    }





 

    // < span className = "hz1" > 取餐码</ >
    return <>   <div className="c fd1">
        <div className="c1">
            <div className="c2">
                <p className="c3"><b>{FInfo.storeName}</b> </p>
                <p className="c4"><i className="layui-icon layui-icon-ok-circle"></i> 订单已准备完毕，喜欢您再来</p>
            </div>
        </div>


        {FInfo.order_list?.map((v) => {


            return <div key={v.orderId} onClick={() => { navigate("/OrderDetail?orderId=" + v.orderId+"&sid="+v.sid) }} className="c6">
                <div className="h11">
                    <div className="a1">
                        取餐码：{v.取餐信息?.pickupCode || <IconSpin style={{ color:"#ff9800"}} spin /> } <i className="layui-icon layui-icon-right"></i>
                    </div>
                    
                    <div className="a2" ><Tag color="red">{v.取餐信息?.eatTypeName||""}</Tag>     <Text style={{marginLeft:10,color:"#666"}}  >查看详情</Text><IconChevronRight style={{color:"#999"}} /></div>
                </div>


               
                {v.取餐信息?.orderProductList?.map((v2) => {


                    console.log("循环88");

                    return <div key={v2.id} className="h2"> 
                        <div className="b2">
                            <p className="bt">{v2.productName}</p>
                            {v2.comboItemList?.map((v3) => <p key={v3.productCode} className="b3">{v3.name}×{v3.quantity}</p>)}

                        </div>
                    </div>


                })}

            </div>;

        })}

    </div></>;

}