import React, { createContext, useContext, useState } from 'react';

import FullScreenLoading from "@/components/FullScreenLoading";


const AppContext=createContext();
 
export function AppProvider({children}){
 
 
    const [sid, setSid] = useState("f34c3cc626e4b84b8d984e1a7d8cb45e_");  //sid
 
    const [LoadingShow, setLoadingShow] = useState(false);  //sid
     
    return <>
        <AppContext.Provider value={{ sid, setSid, setLoadingShow }}>
{children} 
            <FullScreenLoading show={LoadingShow}/>
        </AppContext.Provider>
     

    </>;
};



 

export function useAppContext(){

    return useContext(AppContext);

}

 

export function Extract_sid(text) {
 
    // 按行分割输入文本
    const lines = text.trim().split('\n');
    const extractedStrings = [];

    // 遍历每一行
    lines.forEach(line => {
        let parts = line.split('----'); 
        extractedStrings.push((parts[1] || parts[0]).trim()); 
    });

    // 将提取出的字符串重新组合为新的文本，每行一个字符串
    return extractedStrings.join('\n');


}




