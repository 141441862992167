

import { Table, Space, Badge, Tag, Row, Col, Form, Modal, Button, Toast, Divider } from '@douyinfe/semi-ui';
import { useState, useRef, useEffect } from "react";
import i from "@/api/axiosInstance";
import { Typography } from '@douyinfe/semi-ui';

import FullScreenLoading from "@/components/FullScreenLoading";


export default function Settings() {

 



    const { Section, Input, DatePicker, TimePicker, Select, Switch, InputNumber, Checkbox, CheckboxGroup, RadioGroup, Radio } = Form;

     
    const formApi1 = useRef(); //获取搜索表单1 API

    useEffect(() => {
 
        i.post("s.php?f=get_settings").then((r) => {

            formApi1.current.setValues(r.data);//更新表单

            console.log(formApi1.current);
        })



    }, [])
    function saveSettings(values) { 
        i.post("s.php?f=save_settings", values).then(r => {

            Toast.info({ content: r.data.msg });

        });
    }



    let gutter = [16,16];//间隔
    return (
        <>  
            <Form onSubmit={v => saveSettings(v)} getFormApi={formApi => formApi1.current = formApi} style={{ padding: "0 20px" }}>
                <Section text={'参数设置'}>


                    <Row type='flex' gutter={gutter}>
                        <Col span={12}> <Input field='默认sid' label='默认sid' /></Col>
                        <Col span={12}> <Input field='默认storeCode' label='默认storeCode' /></Col>

                        <Col span={12}>
                            <Input field='腾讯地图KEY' label='腾讯地图KEY' />
                        </Col>




                        <Col span={12}>
                        <Input field='余额支付密码' label='余额支付密码' />
                        </Col>
                        <Col span={12}>
                            <Form.RadioGroup field="开启自动下单">
                                <Form.Radio value="是">是</Form.Radio>
                                <Form.Radio value="否">否</Form.Radio>
                            </Form.RadioGroup>

                        </Col>




                        <Col span={12}>
                            <Form.RadioGroup field="无券不允许下单">
                                <Form.Radio value="是">是</Form.Radio>
                                <Form.Radio value="否">否</Form.Radio>
                            </Form.RadioGroup>
                        </Col>
                        <Col span={12}>
                            <Form.RadioGroup field="自动同步卡券">
                                <Form.Radio value="是">是</Form.Radio>
                                <Form.Radio value="否">否</Form.Radio>
                            </Form.RadioGroup>

                        </Col>



                        <Col span={12}>
                            <Form.RadioGroup field="记录下单日志">
                                <Form.Radio value="是">是</Form.Radio>
                                <Form.Radio value="否">否</Form.Radio>
                            </Form.RadioGroup>
                        </Col>
                        <Col span={12}>
                            <Form.RadioGroup field="用券顺序">
                                <Form.Radio value="有效期">有效期</Form.Radio>
                                <Form.Radio value="置顶时间">置顶时间</Form.Radio>
                            </Form.RadioGroup>
                        </Col>



                        <Col span={12}>
                            <Input field='阿奇索AppSecret' label='阿奇索AppSecret' />
                        </Col>
                    

                        <Col span={12}>
                            <Input field='用户下单域名' label='用户下单域名' />
                        </Col>
                        <Col span={12}>
                            <Input field='IP提取地址' label='IP提取地址' />

                        </Col>

                        <Col span={24}>
                        <Button type='primary' htmlType='submit' theme='solid' style={{ width: 120, marginTop: 12, marginRight: 4 }}>保存</Button>
                        </Col>

                        
               

                <Space style={{fontSize:14,marginTop:30}} span={24}>
               
               
                <Typography.Text mark  >下单接收数据地址: {window.location.protocol}//{window.location.hostname}/s/s1.php </Typography.Text>
                  
              
               <Typography.Text mark  >退款接收数据地址: {window.location.protocol}//{window.location.hostname}/s/s2.php   </Typography.Text>
               

               <Typography.Text mark  >支付宝待支付列表:  {window.location.protocol}//{window.location.hostname}/s/s.php?f=waiting_payments </Typography.Text>

               <Typography.Text mark  >支付宝支付状态修改地址: {window.location.protocol}//{window.location.hostname}/s/s.php?f=payments_status</Typography.Text>
                

  
                        </Space>

                    </Row>


                </Section>

 
               

             
            </Form>
        </>
    );




}