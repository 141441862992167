
import { useAppContext, Extract_sid } from "@/context/context";
import { useState, useRef, useEffect } from "react";
import { Tooltip, Space, Popconfirm, Tag, Dropdown, SideSheet, Form, Modal, Button, Toast, Divider } from '@douyinfe/semi-ui';
import { IconHelpCircle } from "@douyinfe/semi-icons";
import i from "@/api/axiosInstance";


export default function AddAccount({ sync_function, classify, SlideVisible, setSlideVisible, couponID }) {
    
    const formApi2 = useRef(); //获取formapi 
 
    useEffect(() => {

 
    }, [])
 
    async function handleForm() { //处理表单提交

        //表单 验证
        formApi2.current.validate().then(async values => {

            //添加list 循环同步权益

            let list = [];  //循环同步
  
            
             let e_sid= Extract_sid(values.sid列表);
            formApi2.current.setValue("sid列表", e_sid); 
            for (const v of e_sid.split("\n")) {    //添加数组
                list.push({ 可使用次数: values.可使用次数, 分类: values.分类, 备注: values.备注, sid: v.trim() });
            }

         
            let r = await sync_function({ list: list });  //同步优惠券


        }).catch((error)=>{ console.log(error);});


    }
 

    return (<SideSheet motion={false} width="700px" title="导入普通账号" visible={SlideVisible} headerStyle={{ borderBottom: '1px solid var(--semi-color-border)' }} onCancel={() => setSlideVisible(false)} >

        <Form getFormApi={formApi => formApi2.current = formApi}   >

            <Form.Input field="分类" placeholder="请输入分类" rules={[{ required: true, message: '不能为空' }]} />

            <Space wrap>
               
                {classify.map((v, index) => <Tag size="large" onClick={() => { formApi2.current.setValue("分类",v.分类) }} key={index} color="white">{v.分类}</Tag>)}
            </Space>

            <Form.InputNumber field='可使用次数' rules={[{ required: true, message: '不能为空' }]} />


            <Form.Input field='备注' />

            <Form.TextArea field='sid列表' rules={[{ required: true, message: '不能为空' }]} placeholder='批量导入sid的券 一行一个sid ' />

            <Space style={{ width: "100%", marginTop: "40px", justifyContent: "flex-end", padding: "15px 0", borderTop: "1px solid #eee" }}>

            <Button theme='solid' type='primary' onClick={handleForm} style={{ width: 140 }}>确认</Button> </Space>


        </Form>

    </SideSheet>);


}