
import { Table, Space, Popconfirm, Tag, InputNumber, Tooltip, SideSheet, Form, Modal, Button, Toast, Divider } from '@douyinfe/semi-ui';
import { useState, useRef, useEffect } from "react";
import { IconHelpCircle } from "@douyinfe/semi-icons";
import i from "@/api/axiosInstance";
import { useAppContext } from "@/context/context";
 

import AddAccount from "@/components/AddAccount";

export default function Main() {



    const { setLoadingShow } = useAppContext();  //加载层隐藏或者显示 

    const columns = [

        {
            title: 'sid',
            dataIndex: 'sid',
            render: (text, record, index) => {
                return (<>{text} <Tag color="green" > {record.手机号} </Tag>  {record.置顶 == 1 && <Tag color="light-blue" >已置顶</Tag>} </>)

            }
        },
        
        {
            title: '分类',
            dataIndex: '分类',
        },
        {
            title: '账号状态',
            dataIndex: '账号状态',
            render: (text, record, index) => {

                return (<>{text == "异常" ? <Tooltip content={record.异常通知}><Tag color="red" >{text} <IconHelpCircle style={{ marginLeft: 5 }} /></Tag>   </Tooltip> : <Tag color="green" >{text}</Tag>}</>  )
                
            }
        },
        {
            title: '入库状态',
            dataIndex: '入库状态',
            render: (text, record, index) => {

                return (<Tag color={text == "正常" ? "green" : "red"} > {text} </Tag>)

            }
        },
 
        {
            title: '最新使用时间',
            dataIndex: '最新使用时间',
        },
         
      
        {
            title: '可使用次数',
            dataIndex: '可使用次数',
            render: (text, record, index) => {

                return (<InputNumber onChange={(v) => {
                    i.post("s.php?f=update_data2", { id: record.id, value: v }).then(r => { Toast.info({ "content": r.data.msg }); getList();  }); }} style={{width:60}} defaultValue={text} /> )

            }
        },
    

        {
            title: '备注',
            dataIndex: '备注',
        },
        {
            title: '操作',
            dataIndex: 'owner',
            render: (text, row, index) => {

                return (<Space>


                    <Popconfirm
                        title="是否要删除"
                        onConfirm={() => { deleteDate(row.id) }}
                    >
                        <Button theme='solid' type='danger' >删除</Button>
                    </Popconfirm>
                   
             
                </Space>);
            },
        }
    ];


    function change_status1(field, state) { // value 入库或者出库状态

        let list = [];
        if (selectedRows.length < 1) { Toast.info({ content: "最少需要选中一条数据" }); return false; }
        
        for (const v of selectedRows) {
            list.push({ id: v.id, field: field, b: "mdl_ordinary_account", value: state });
        }

        i.post("s.php?f=update_data", { list: list }).then(r => {

            Toast.info({ "content": r.data.msg });

            if (r.data.code == 1) { getList(); }

        })

    }




  



    async function sync_function(values) {  //同步优惠券 
        setLoadingShow(true);
        let r = await i.post("s.php?f=handleAccount", { ...values });
        setLoadingShow(false);

        Modal.info({
            title: '操作成功', hasCancel: false,
            width: "650px",
            content: <><p style={{ fontSize: "16px", lineHeight: "1.5" }}>
                新增账号数量:{r.data.succeed} <br /> 
                {r.data.error > 0 && <Tag>向数据库添加数据时出错,数量:{r.data.error}</Tag>}
                {r.data.info.map((v, index) => <Tag key={index} color="red">{v}</Tag>)}
            </p></>
        });

        getList();


        return r;


    }


    async function deleteDate(id) {

        const r = await i.post("s.php?f=delete_data", { id: id, b: "mdl_ordinary_account" });
        Toast.info({ "content": r.data.msg });
        getList();
    }
 
    const formApi1 = useRef(); //获取搜索表单1 API

    const formApi2 = useRef(); //获取formapi



    const [data, setData] = useState([]);

    let limit = 30; //分页页数
    async function getList(page) {

        page = page ? page : currentPage;

        const r1 = await i.post(`s.php?f=get_AccountList&limit=${limit}&page=${page}`, formApi1.current.getValues());


        setData(r1.data);

    }
    const [classifyList, setClassifyList] = useState([]); 
    useEffect(() => {

        formApi1.current.setValues({ "入库状态":"0"});

 
        getList(1);  //获取第一页商品

 

        i.post("s.php?f=get_AccountClassifyList&limit=10000").then((r) => { //获取不重复的分类列表
            setClassifyList(r.data.data);
        })

 

    }, [])


    const [SlideVisible, setSlideVisible] = useState(false); //侧边栏显示或隐藏


 

    const [currentPage, setPage] = useState(1);

    const [selectedRows, setSelectedRows]=useState([]);
    return (<>
        <Space> 
            <Form getFormApi={formApi => formApi1.current = formApi} layout='horizontal' >

                <Form.Input
                    placeholder="输入关键字搜索"
                    field='key'
                    label={{
                        text: '搜索'

                    }}
                    style={{ width: 176 }}
                />
                <Form.Select field="分类"   >
                    <Form.Select.Option value="0">全部</Form.Select.Option>
                     
                    {classifyList.map((v, index) => <Form.Select.Option key={v.分类 + "-" + index} value={v.分类}>{v.分类}</Form.Select.Option>)}


                </Form.Select>

                <Form.Select field="入库状态" label="状态" >
                    <Form.Select.Option value="0">全部</Form.Select.Option>
                    <Form.Select.Option value="正常">正常</Form.Select.Option>
                    <Form.Select.Option value="出库">出库</Form.Select.Option>
                </Form.Select>


                <Space style={{ display: 'flex', alignItems: 'flex-end' }}>
                    <Button type='primary' onClick={() => getList(1)} theme='solid'>搜索</Button>
                    <Button style={{marginRight:"50px"}} onClick={() => { setTimeout(() => { getList(1); }, 200); }} htmlType="reset">重置</Button>

                    <Button type='primary'  onClick={() => setSlideVisible(true)} theme='solid' >导入</Button>


  
                    <Popconfirm
                        title="是否要删除"
                        onConfirm={() => { 
                            let t1 = [];
                            for (const v of selectedRows) {
                                t1.push(v.id);
                            }  
                            deleteDate(t1) ; 
                        }}
                    >
                        <Button type='danger' theme='solid' >批量删除</Button>

                    </Popconfirm>
                 

                    <Button type='warning' onClick={() => { change_status1("入库状态","出库") }} theme="solid"  >出库</Button>
                    <Button type='warning' onClick={() => { change_status1("入库状态") }} theme="solid"  >入库</Button>


                    <Button type='warning' onClick={() => { change_status1("置顶", "1") }} theme="solid" >置顶(优先使用)</Button>
                    <Button type='warning' onClick={() => { change_status1("置顶", "0") }} theme="solid" >取消置顶</Button>

                </Space>



            </Form>





        </Space>
        <Divider margin='12px'>
        </Divider>
        <Table columns={columns} scroll={{ y: window.innerHeight - 285 }} rowSelection={{ 
            onChange: (selectedRowKeys, selectedRows) => { 
                setSelectedRows(selectedRows);
            },
        }} dataSource={data.data} rowKey="id" pagination={{
            currentPage,
            pageSize: limit,
            total: data.count,
            onPageChange: (page) => { setPage(page); getList(page); },
        }} />


 
        <AddAccount SlideVisible={SlideVisible} classify={classifyList} setSlideVisible={setSlideVisible} sync_function={sync_function}></AddAccount>




    </>);

}