
import { Table, Space, Popconfirm, Tag, InputNumber, Dropdown, SideSheet, Form, Modal, Button, Toast, Divider } from '@douyinfe/semi-ui';
import { useState, useRef, useEffect } from "react";
import { IconTreeTriangleDown } from "@douyinfe/semi-icons";
import i from "@/api/axiosInstance";
import { useAppContext } from "@/context/context";
import { format, subDays } from 'date-fns';

import AddAccount from "@/components/AddAccount";

export default function Main() {



    const { setLoadingShow } = useAppContext();  //加载层隐藏或者显示 

    const columns = [

        {
            title: '订单号',
            width:210,
            dataIndex: '系统订单号',

            render: (text, record, index) => {

                return (<><div>{text}</div> <Tag color="green" >创建时间: {record.订单创建时间} </Tag> </>)

            }
          
        },
          
        {
            title: '订单状态',
            dataIndex: '订单状态',
            render: (text, record, index) => {

                let color="white";
                if(text=="已作废"){ color="red"; }
                if(text=="未使用"){ color="light-blue";  } 
                return (<> <Tag color={color} >{text} </Tag> </>)

            }

        },

        
        {
            title: '方案编码',
            dataIndex: '方案编码',
            render: (text, record, index) => {

                return (<><div>{text}</div> <Tag color="green" > {record.方案名称} </Tag> </>)

            }
        }, 
        {
            title: '兑换码',
            dataIndex: '兑换码',
              render: (text, record, index) => {

                  return (<><a target="_blank" href={"/&oid=" + text}>{text}</a> </>)

            }

        },
       
        {
            title: '数量',
            dataIndex: '数量' 
        },

 
        {
            title: '商品名称',
            dataIndex: '商品名称',
        },
        {
            title: '备注',
            dataIndex: '订单提示信息'

        },
        {
            title: '操作',
            dataIndex: 'owner',
            render: (text, row, index) => {

                return (<Space>


                {row.订单状态=="未使用"&&     <Popconfirm
                        title="是否要恢复这个订单"
                        onConfirm={() => { update_data3(row.系统订单号,"未使用") }}
                    >
                        <Button theme='solid'  >恢复</Button>
                    </Popconfirm>}
                   
                 
                {row.订单状态=="已作废"&&     <Popconfirm
                        title="是否要作废这个订单"
                        onConfirm={() => { update_data3(row.系统订单号,"已作废") }}
                    >
                        <Button theme='solid' type='danger' >作废</Button>
                    </Popconfirm>}  
                  
             
                </Space>);
            },
        }
    ];


  

    async function update_data3(oid,value) {  //作废和恢复

        const r = await i.post("s.php?f=update_data3", { value: value, oid: oid });
        Toast.info({ "content": r.data.msg });
        getList();
    }
 
    const formApi1 = useRef(); //获取搜索表单1 API

    const formApi2 = useRef(); //获取formapi



    const [data, setData] = useState([]);

    let limit = 30; //分页页数
    async function getList(page) {

        page = page ? page : currentPage;

        const r1 = await i.post(`s.php?f=get_order1_list&type=1&limit=${limit}&page=${page}`, formApi1.current.getValues());


        setData(r1.data);

    }
   
    useEffect(() => {

        formApi1.current.setValues({ "订单状态":"0",订单创建时间:[new Date(),new Date()]});

 
        getList(1);  //获取第一页商品

 

   
 

    }, [])


    const [SlideVisible, setSlideVisible] = useState(false); //侧边栏显示或隐藏


 

    const [currentPage, setPage] = useState(1);

    const [selectedRows, setSelectedRows]=useState([]);





function setCreateDate(n){

    console.log(n);
    
    const d1 = subDays(new Date(), n);
    formApi1.current.setValues({订单创建时间:[d1,new Date()]});

}


    return (<>
        <Space> 
            <Form getFormApi={formApi => formApi1.current = formApi} layout='horizontal' >

                <Form.Input
                    placeholder="输入关键字搜索"
                    field='key'
                    label={{
                        text: '搜索'

                    }}
                    style={{ width: 176 }}
                />
             

                <Form.Select field="订单状态"  >
                    <Form.Select.Option value="0">全部</Form.Select.Option>
                    <Form.Select.Option value="未使用">未使用</Form.Select.Option>
                    <Form.Select.Option value="已作废">已作废</Form.Select.Option>
                    <Form.Select.Option value="已使用,下单成功">已使用,下单成功</Form.Select.Option>
                    <Form.Select.Option value="已使用,下单失败">已使用,下单失败</Form.Select.Option>
                </Form.Select>

                <Form.DatePicker field="订单创建时间" style={{ width: 260 }} onChange={(date) => { console.log(date); }} type="dateRange" label={<Space>创建时间<Tag color="white" onClick={()=>{ setCreateDate(0) }} >今日</Tag><Tag color="white" onClick={()=>{ setCreateDate(1)}} >昨天</Tag><Tag color="white" onClick={()=>{ setCreateDate(3)}}>3天</Tag> <Tag color="white" onClick={()=>{ setCreateDate(7)}}>7天</Tag></Space>} />





                <Space style={{ display: 'flex', alignItems: 'flex-end' }}>
                    <Button type='primary' onClick={() => getList(1)} theme='solid'>搜索</Button>
                    <Button style={{marginRight:"50px"}} onClick={() => { setTimeout(() => { getList(1); }, 200); }} htmlType="reset">重置</Button>

                    {/* <Button type='primary'  onClick={() => setSlideVisible(true)} theme='solid' >导入</Button>
 */}

  
                    {/* <Popconfirm
                        title="是否要删除"
                        onConfirm={() => { 
                            let t1 = [];
                            for (const v of selectedRows) {
                                t1.push(v.id);
                            }  
                            deleteDate(t1) ; 
                        }}
                    >
                        <Button type='danger' theme='solid' >批量删除</Button>

                    </Popconfirm>
                */}

                </Space>



            </Form>





        </Space>
        <Divider margin='12px'>
        </Divider>
        <Table columns={columns} rowSelection={{ 
            onChange: (selectedRowKeys, selectedRows) => { 
                setSelectedRows(selectedRows);
            },
        }} dataSource={data.data} rowKey="id" pagination={{
            currentPage,
            pageSize: limit,
            total: data.count,
            onPageChange: (page) => { setPage(page); getList(page); },
            }} scroll={{ y: window.innerHeight-285 }} />


 
       


    </>);

}