import { useState } from "react"; 

import { Spin } from '@douyinfe/semi-ui';

 
export default function FullScreenLoading({ show }){  
    return (<>{show && <div className="fullscreen-overlay" >
        <Spin size="large" />
    </div>}
</>);   
 
}

 



