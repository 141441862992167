import { useState } from "react";
import { Layout, Nav, Button, ConfigProvider, Breadcrumb, Skeleton, Avatar } from '@douyinfe/semi-ui';

import { IconDescriptions, IconToast, IconAvatar, IconBreadcrumb, IconTree, IconBanner, IconBadge } from '@douyinfe/semi-icons-lab';
import { IconBell, IconHelpCircle, IconExit, IconHome, IconHistogram, IconLive, IconSetting, IconSemiLogo } from '@douyinfe/semi-icons';

import { Router, Route, useParams, useNavigate, Routes } from "react-router-dom";

import DiscountCoupon from './DiscountCoupon';
import Settings from './Settings';
import StoreList from './StoreList';
import PlanList from './PlanList'; 
import CouponGoodsList from './CouponGoodsList';
import Coupon1 from './Coupon1';
import Coupon2 from './Coupon2';
import Coupon3 from './Coupon3';
import Benefit1 from './Benefit1';
import Benefit2 from './Benefit2';
import OrderList from './OrderList';
import Order1 from './Order1';
import NotifyList from './NotifyList';


 
export default function ManageHome() {
    const { Header, Sider, Content } = Layout;

    const [selectedKeys, setSelectedKeys] = useState();
  
    const navigate = useNavigate();

    const commonStyle = {
        height: 64,
        lineHeight: '64px',
        background: 'var(--semi-color-fill-0)'
    };
 


    

    const handleItem=(data)=>{
 
      
      setSelectedKeys([data.itemKey]); 
        navigate(data.itemKey);
    }
 


    return (  
       
    <Layout className="components-layout-demo">
        <Sider style={{ height:"100vh"}}> 
            <Nav 
                onSelect={handleItem}
               
                style={{ height:"100vh"  }}
                selectedKeys={selectedKeys}
                defaultOpenKeys={["L1","L2"]}
                items={[
                    // { itemKey: 'user', text: '用户管理', icon: <IconAvatar /> },
                    // { itemKey: 'union', text: '活动管理', icon: <IconDescriptions /> },
                    {
                        text: '基础数据',
                        icon: <IconTree />,
                        itemKey: 'L1',
                        items: [{ itemKey: 'DiscountCoupon', text: '卡券列表' }, { itemKey: 'CouponGoodsList', text: '用券商品' }, { itemKey: 'Settings', text: '参数设置' }, { itemKey: 'StoreList', text: '店铺对接'}]
                    },

                    {
                        text: '自动下单',
                        icon: <IconBreadcrumb />,
                        itemKey: 'L2',
                        items: [{ itemKey: 'PlanList', text: '方案管理' },  { itemKey: 'Order1', text: '发货记录' },{ itemKey: 'OrderList', text: '订单管理' }, { itemKey: 'Coupon1', text: '优惠券管理' }, { itemKey: 'Coupon2', text: '券码明细' }, { itemKey: 'Coupon3', text: '优惠券报表' }, { itemKey: 'Benefit1', text: '权益账号' }, { itemKey: 'Benefit2', text: '普通账号' },{ itemKey: 'NotifyList', text: '异常通知' }]
                    },
                ]}
                header={{
                    logo: <IconToast style={{ height: '36px', fontSize: 36 }} />,
                    text: 'Semi 运营后台'
                }}
               

                footer={{
                    collapseButton: true,
                }}
            />
                
                
           </Sider>
        <Layout style={{maxHeight:"100vh"}}>
            <Header style={commonStyle}>
                

                <Nav 
                    mode="horizontal"
                    footer={
                        <>
                            <Button
                                theme="borderless"
                                icon={<IconBell size="large" />}
                                style={{
                                    color: 'var(--semi-color-text-2)',
                                    marginRight: '12px',
                                }}
                            />
                            <Button
                                theme="borderless"
                                icon={<IconHelpCircle size="large" />}
                                style={{
                                    color: 'var(--semi-color-text-2)',
                                    marginRight: '12px',
                                }}
                            />
                            
                            <div style={{ display: "flex", alignItems: "center" }}> <IconAvatar style={{ fontSize: "30px" }} /> <span style={{padding:"0 5px"}}>管理员</span>  <Button type="primary" onClick={()=>{ localStorage.t="";navigate("/m_login") }} icon={<IconExit />}>退出</Button></div>
                        </>
                    }
                ></Nav>





            </Header>
            <Content style={{padding:"10px",overflow:"auto"}}>
                
              
                    <Routes>
                    <Route exact path="/" element={<DiscountCoupon />} />
                    <Route path="DiscountCoupon" element={<DiscountCoupon />} />
                    <Route path="Settings" element={<Settings />} /> 
                    <Route path="PlanList" element={<PlanList />} />  
                    <Route path="StoreList" element={<StoreList />} />    
                    <Route path="Coupon1" element={<Coupon1 />} />    
                    <Route path="Coupon2" element={<Coupon2 />} />    
                    <Route path="Coupon3" element={<Coupon3 />} />    
                    <Route path="CouponGoodsList" element={<CouponGoodsList />} /> 
                    <Route path="Benefit1" element={<Benefit1 />} />
                    <Route path="Benefit2" element={<Benefit2 />} />     
                        <Route path="OrderList" element={<OrderList />} /> 
                        <Route path="Order1" element={<Order1 />} />  
                        <Route path="NotifyList" element={<NotifyList />} />  
                    </Routes>
                

                
            </Content>

        </Layout>
            </Layout>);




}