
import { Table, Space, Popconfirm, Tag, InputNumber, Dropdown, SideSheet, Form, Modal, Button, Toast, Divider } from '@douyinfe/semi-ui';
import { useState, useRef, useEffect } from "react";
import { IconTreeTriangleDown } from "@douyinfe/semi-icons";
import i from "@/api/axiosInstance";
import { format, subDays } from 'date-fns';
import { useAppContext } from "@/context/context";
 

import AddAccount from "@/components/AddAccount";

export default function Main() {




    function setCreateDate(n){

        console.log(n);
        
        const d1 = subDays(new Date(), n);
        formApi1.current.setValues({下单时间:[d1,new Date()]});
    
    }

    const { setLoadingShow } = useAppContext();  //加载层隐藏或者显示 

    const columns = [

        {
            title: '订单号',
            dataIndex: '系统订单号',
            render: (text, record, index) => {

                return (<><div>{text}</div> <Tag color="green" ></Tag> </>)

            }
          
        },
        
        {
            title: '麦当劳订单号',
            dataIndex: '麦当劳订单号',
            width:250,
            render: (text, record, index) => {

                return (<><div>{text}</div> <Tag color="green" >下单时间:{record.下单时间} </Tag> </>)

            }
        },
        {
            title: '方案编码',
            dataIndex: '方案编码',
            render: (text, record, index) => {

                return (<><div>{text}</div> <Tag color="green" >{record.方案名称} </Tag> </>)

            }
        },
 
        {
            title: '麦当劳订单状态',
            width:90,
            dataIndex: '麦当劳订单状态',
            render: (text, record, index) => {

                return (<><div> <Tag color={text == "green" ? "light-green" :"orange"}  >{text}</Tag> </div> {record.支付成功时间 && <Tag color="green" >{record.支付成功时间}</Tag>}</>)

            }
        },
        {
            title: '优惠券信息',
            dataIndex: '优惠券ID',
            width:190,
            render: (text, record, index) => {

                return (<><div>{text}</div> {text && <div> <Tag color="purple" >{record.优惠券名称}</Tag> <Tag color="green" >{record.优惠券券码}</Tag></div>}</>)

            }
        },
        {
            title: '餐厅信息',
            dataIndex: '餐厅信息',
            render: (text, record, index) => { 
                return (<><div>{text}</div></>) 
            }
        },

        {
            title: '取餐码',
            dataIndex: '取餐码',
            render: (text, record, index) => {
                return (<><div>{text}</div></>)
            }
        },

 

        {
            title: 'sid',
            dataIndex: 'sid',
        },
        {
            title: '操作',
            dataIndex: 'owner',
            render: (text, row, index) => {

                return (<Space>


                    {/* <Popconfirm
                        title="是否要删除"
                        onConfirm={() => { deleteDate(row.id) }}
                    >
                        <Button theme='solid' type='danger' >删除</Button>
                    </Popconfirm> */}
                   
             
                </Space>);
            },
        }
    ];


    function change_status1(field, state) { // value 入库或者出库状态

        let list = [];
        if (selectedRows.length < 1) { Toast.info({ content: "最少需要选中一条数据" }); return false; }
        for (const v of selectedRows) {
            list.push({ id: v.id, field: field, b: "mdl_ordinary_account", value: state });
        }

        i.post("s.php?f=update_data", { list: list }).then(r => {

            Toast.info({ "content": r.data.msg });

            if (r.data.code == 1) { getList(); }

        })

    }



    async function sync_function(values) {  //同步优惠券 
        setLoadingShow(true);
        let r = await i.post("s.php?f=handleAccount", { ...values });
        setLoadingShow(false);

        Modal.info({
            title: '操作成功', hasCancel: false,
            width: "650px",
            content: <><p style={{ fontSize: "16px", lineHeight: "1.5" }}>
                新增账号数量:{r.data.succeed} <br /> 
                {r.data.error > 0 && <Tag>向数据库添加数据时出错,数量:{r.data.error}</Tag>}
                {r.data.info.map((v, index) => <Tag key={index} color="red">{v}</Tag>)}
            </p></>
        });

        getList();


        return r;


    }


    async function deleteDate(id) {

        const r = await i.get("s.php?f=delete_data", { id: id, b: "mdl_order_m" });
        Toast.info({ "content": r.data.msg });
        getList();
    }
 
    const formApi1 = useRef(); //获取搜索表单1 API

    const formApi2 = useRef(); //获取formapi



    const [data, setData] = useState([]);

    let limit = 30; //分页页数
    async function getList(page) {

        page = page ? page : currentPage;

        const r1 = await i.post(`s.php?f=get_order_list&limit=${limit}&page=${page}`, formApi1.current.getValues());


        setData(r1.data);

    }
   
    useEffect(() => {

        formApi1.current.setValues({ "订单状态":"0",下单时间:[new Date(),new Date()]});
 
        getList(1);  //获取第一页商品

 

   
 

    }, [])


    const [SlideVisible, setSlideVisible] = useState(false); //侧边栏显示或隐藏


 

    const [currentPage, setPage] = useState(1);

    const [selectedRows, setSelectedRows]=useState([]);
    return (<>
        <Space> 
            <Form getFormApi={formApi => formApi1.current = formApi} layout='horizontal' >

                <Form.Input
                    placeholder="输入关键字搜索"
                    field='key'
                    label={{
                        text: '搜索'

                    }}
                    style={{ width: 176 }}
                />
             

                <Form.Select field="麦当劳订单状态"  >
                    <Form.Select.Option value="0">全部</Form.Select.Option>
                    <Form.Select.Option value="待支付">待支付</Form.Select.Option>
                    <Form.Select.Option value="配餐中">配餐中</Form.Select.Option>
                    <Form.Select.Option value="已完成">已完成</Form.Select.Option>
                    <Form.Select.Option value="已取消">已取消</Form.Select.Option>
                </Form.Select>

                <Form.DatePicker field="下单时间" style={{ width: 260 }} onChange={(date) => { console.log(date); }} type="dateRange" label={<Space>下单时间<Tag color="white" onClick={()=>{ setCreateDate(0) }} >今日</Tag><Tag color="white" onClick={()=>{ setCreateDate(1)}} >昨天</Tag><Tag color="white" onClick={()=>{ setCreateDate(3)}}>3天</Tag> <Tag color="white" onClick={()=>{ setCreateDate(7)}}>7天</Tag></Space>} />





                <Space style={{ display: 'flex', alignItems: 'flex-end' }}>
                    <Button type='primary' onClick={() => getList(1)} theme='solid'>搜索</Button>
                    <Button style={{marginRight:"50px"}} onClick={() => { setTimeout(() => { getList(1); }, 200); }} htmlType="reset">重置</Button>

                    {/* <Button type='primary'  onClick={() => setSlideVisible(true)} theme='solid' >导入</Button>
 */}

  
                    {/* <Popconfirm
                        title="是否要删除"
                        onConfirm={() => { 
                            let t1 = [];
                            for (const v of selectedRows) {
                                t1.push(v.id);
                            }  
                            deleteDate(t1) ; 
                        }}
                    >
                        <Button type='danger' theme='solid' >批量删除</Button>

                    </Popconfirm>
                */}

                </Space>



            </Form>





        </Space>
        <Divider margin='12px'>
        </Divider>
        <Table columns={columns} rowSelection={{ 
            onChange: (selectedRowKeys, selectedRows) => { 
                setSelectedRows(selectedRows);
            },
        }} dataSource={data.data} rowKey="麦当劳订单号" pagination={{
            currentPage,
            pageSize: limit,
            total: data.count,
            onPageChange: (page) => { setPage(page); getList(page); },
            }} scroll={{ y: window.innerHeight-285 }} />


 
       


    </>);

}