

//import { useState } from "react"; 
import { Select, Tag, Badge, Toast, Divider, Input } from "@douyinfe/semi-ui";
import { IconSearch, IconTag, IconMapPin } from "@douyinfe/semi-icons";
import { MD5 } from 'crypto-js';
import { useAppContext } from "../../context/context";
import axios from "axios";
import { useState, useEffect, useRef } from "react";
import i from "@/api/axiosInstance";

import { useNavigate, useSearchParams } from "react-router-dom";




export default function Home() {

    const navigate = useNavigate();

    let sid = useRef("");  //获取sid

    const [searchParams] = useSearchParams();
    const oid = searchParams.get('oid');  //获取URL 的参数




    function getCity() { //获取城市

        return axios({
            url: 'https://api.mcd.cn/bff/store/cities/group',
            responseType: 'json',
            headers: {
                "ct": "102",
                "v": "6.0.18.1",
                "p": "102",
                "language": "cn",
                "tid": "00003TuN",
                "Content-Type": "application/json; charset=UTF-8",
                "sid": sid.current,
                "sign": MD5(`ct=102#language=cn#p=102#sid=${sid.current}#v=6.0.18.1#key=97741bd7-21bb-4f0d-8b4a-501a089cd208`).toString(),
                "token": ""
            },
        });


    }


    async function getNearbyStore(latitude, longitude) {


        let n1 = await i.post("s.php?f=getNearbyStore", { latitude, longitude }); //获取订单信息 


        NearbyStore.current = n1.data.data.stores;



        
     
    let t1 = removeDuplicatesAndCount(cityStore.current, NearbyStore.current);    
    setCard_list(t1);  //赋值

    }









    const [cityList, setCityList] = useState([]); //城市列表
    const [CurrentCity, setCurrentCity] = useState("");  //当前城市


    //城市下的门店列表 和输入框的关键字
    const [searchKey, setSearchKey] = useState("");


    let NearbyStore = useRef([]);

    let cityStore = useRef([]);
   
    const [card_list, setCard_list] = useState([]);


    function getStoreAll(pageNo, StoreAllList) {  // 
        axios({
            responseType: 'json', url: `https://api.mcd.cn/bff/store/stores?cityCode=${CurrentCity}&pageNo=${pageNo}&pageSize=100`,

            headers: {

                "ct": "102",
                "v": "6.0.18.1",
                "p": "102",
                "token": "",
                "language": "cn",
                "tid": "00003TuN",
                "Content-Type": "application/json; charset=UTF-8",
                "sid": sid.current,
                "sign": MD5(`ct=102#language=cn#p=102#sid=${sid.current}#v=6.0.18.1#cityCode=${CurrentCity}#pageNo=${pageNo}#pageSize=100#key=97741bd7-21bb-4f0d-8b4a-501a089cd208`).toString()
            },

        }).then((r) => {
            if (r.data.code != 200) { console.log("数据出现错误"); return false; } //接口错误


            StoreAllList.push(...r.data.data.stores);  //list添加数据

  

            if (pageNo <= Math.floor(r.data.data.totalSize / 100)) {
                //还有数据 递归获取
                getStoreAll(pageNo + 1, StoreAllList);  //递归获取数据

            } else {


                
                if (NearbyStore.current.length > 0) {  //获取附近商店
                    //合并数组
                     StoreAllList = removeDuplicatesAndCount(StoreAllList, NearbyStore.current);  
                    console.log("合并数组", StoreAllList);
                }  
                cityStore.current=StoreAllList;

                setCard_list(StoreAllList);  //赋值

            }

        });



    }



    useEffect(() => {  //切换城市获取城市餐厅列表 


        if (CurrentCity) {  //城市不为空 
            const pageNo = 1;

            const StoreAllList = [];

            getStoreAll(1, StoreAllList);

        }

        console.log("组件访问或卸载");
    }, [CurrentCity]);








    function removeDuplicatesAndCount(arrayA, arrayB) {
        // 创建一个 Set 来存储 arrayB 中所有对象的 code，以便快速查找
        const codesInB = new Set(arrayB.map(item => item.code));
 
        // 用于保存不重复的元素和计数相同的元素数量
        let countOfDuplicates = 0;
        const filteredArrayA = arrayA.filter(item => {
            if (codesInB.has(item.code)) {
                countOfDuplicates++;
                return false; // 移除该项
            } else {
                return true; // 保留该项
            }
        });

        // 返回过滤后的数组和重复项计数
        if (countOfDuplicates > 0) {

            console.log("存在");

            return [...arrayB, ...filteredArrayA];

        } else {
            console.log("不存在", filteredArrayA, arrayB);

            return filteredArrayA;
        }
    }








    useEffect(() => {  //进入组件渲染就请求 
        //渲染列表后获取本身的IP地址



        if (!oid) { alert("没有订单号"); return false; };





        (async function () {



            navigator.geolocation.getCurrentPosition(
                (position) => {
                    console.log("获取到位置：", position.coords.latitude, position.coords.longitude);
                    getNearbyStore(position.coords.latitude, position.coords.longitude);
                }, (err) => {
                    //获取所有餐厅


                }
            );











            //查询订单状态  如果是已下单直接跳转到 取餐码页面
            let order_info = await i.post("s.php?f=order_info&get_sid=1", { "oid": oid }); //获取订单信息 
            if (!order_info.data.code) { Toast.info({ "content": order_info.data.msg }); return false; } //错误提示 

            if (order_info.data.订单状态 == "已使用,下单成功") {
                navigate("/TakeFood?oid=" + oid);
                return false;
            }

            sid.current = order_info.data.sid; //获取sid 

            i.get("s.php?f=get_location").then((r) => {
                //获取定位后 获取城市列表 
                getCity().then((response) => {
                    response = response.data.data.groups;
                    const city2 = [];
                    for (const v of response) {  //循环每个城市

                        for (const v2 of v.cities) {

                            if (r.data.result?.ad_info?.city == v2.name) {  //匹配以后设置当前城市
                                setCurrentCity(v2.code);
                            }

                            city2.push({ value: v2.code, label: v2.name });
                        }

                    }
                    setCityList(city2);//先请求列表再选中 


                })




            });



            getStoreAll(1, []);



        })();




    }, []);









    function CardList({ list, searchKey }) {

        console.log("LIST:", list);

        if (list.length < 1) {
            return false;
        }


        return (<div style={{ padding: "0 15px" }} className="card_list">
            {
                list.map((d) => {

                    if (searchKey == "" || d.name.indexOf(searchKey) > -1) {

                        return (<div key={d.code} onClick={() => { d.businessStatus == 1 && navigate("/OnlineOrder?storeCode=" + d.code + "&oid=" + oid) }} style={{
                            fontSize: '14px',
                            display: 'flex',
                            border: '1px solid #ddd',
                            marginBottom: '15px',
                            borderRadius: '5px',
                            padding: '10px'
                        }}>
                            <div>  <img style={{
                                width: '130px',
                                display: 'block',
                                marginRight: '5px',
                                borderRadius: '5px'
                            }} src={d.mainPic} alt="" loading="lazy" />
                            </div>
                            <div style={{ flex: 1 }}>
                                <div style={{ fontWeight: "bold" }}>{d.shortName}</div>
                                <div style={{ margin: '5px 0', color: '#999' }}>{d.address}</div>
                                <div style={{ display: 'flex', alignItems: 'center' }}>


                                    <Badge count={d.startTime + "-" + d.endTime} theme='light' />
                                    &emsp;

                                    {d.businessStatus == 0 && (<><Badge count='已打烊' type='danger' /></>)}

                                </div>
                            </div>
                        </div>);


                    }


                })


            }
        </div>
        );

    }







    return (<>

        <div style={{ maxWidth: '600px', margin: 'auto' }}>
            <div style={{ background: "#F8F8F8", padding: "15px" }}><img src="/img/flow.png" alt="" /></div>



            <div style={{ margin: '10px', borderRadius: '5px', display: 'flex', background: '#F4F5F5' }}>
                <Select value={CurrentCity} searchPosition='dropdown' searchPlaceholder="搜索城市" onChange={v => setCurrentCity(v)} optionList={cityList} prefix={<IconMapPin />} filter style={{ width: "160px" }} placeholder="城市">

                </Select>
                <Input placeholder='搜索餐厅' onInput={(e) => setSearchKey(e.target.value)} prefix={<IconSearch />}></Input>
            </div>


            <Divider margin='12px' align='center'> </Divider>
            <CardList list={card_list} searchKey={searchKey}></CardList>


        </div>

    </>);
}