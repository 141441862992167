
import { Table, Space, Image, Tag, Form, Modal, Button, Toast,Divider } from '@douyinfe/semi-ui'; 
import { useState,useRef, useEffect } from "react";
import i from "@/api/axiosInstance"; 

import { useAppContext } from "@/context/context";

export default function Main() {
    
  

    const { setLoadingShow } = useAppContext();  //加载层隐藏或者显示 

    const columns = [
        {
            title: '优惠券ID',
            dataIndex: '优惠券ID',
        },
        {
            title: '优惠券名称',
            dataIndex: '优惠券名称',
        },
       
    
        {
            title: '商品图片',
            dataIndex: '商品图片',
            render: (text, record, index) => {

                

                return (<Image
                    width={40} 
                    src={text}
                />)

            }
        },

        {
            title: '商品ID',
            dataIndex: '商品ID',
        },
        {
            title: '商品名称',
            dataIndex: '商品名称',
        },

        

      {
      title: '添加时间',
      dataIndex: '添加时间',
        } 

    ];
      


 
 



    const formApi1 = useRef(); //获取搜索表单1 API

    const formApi2 = useRef(); //获取formapi



    const [data, setData] = useState([]);
 
    let limit=30; //分页页数
async function getList(page){ 
 
    page = page?page:currentPage;
    
    const r1 = await i.post(`s.php?f=get_couponGoodsList&limit=${limit}&page=${page}`, formApi1.current.getValues());

   
    setData(r1.data);
   
}

 
useEffect(()=>{


    getList(1);  //获取第一页商品


},[])

 


    const [currentPage, setPage] = useState(1);
    return (<>
      <Space>
 
            <Form  getFormApi={formApi => formApi1.current = formApi} layout='horizontal' >
           
            <Form.Input
            placeholder="输入关键字搜索"
                field='key'
                label={{ 
                    text: '搜索'
                   
                }}
                style={{ width: 176 }}
            />

               <div style={{ display: 'flex', alignItems: 'flex-end' }}>
                    <Button type='primary' onClick={() => getList(1)} theme='solid' style={{   marginRight: 4 }}>搜索</Button>
                    <Button onClick={()=>{ setTimeout(() => {  getList(1);  }, 200); }} htmlType="reset">重置</Button>
                </div>
             
  
        </Form>


 

        
    </Space>
      <Divider margin='12px'> 
            </Divider>
        <Table columns={columns} scroll={{ y: window.innerHeight - 285 }} dataSource={data.data} pagination={{
            currentPage,
            pageSize: limit,
            total: data.count,
            onPageChange: (page) => { setPage(page); getList(page);},
        }} />
    </>);

}