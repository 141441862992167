
import { Table, Space, Tooltip, Typography, Image, Input, Checkbox, Row, Col, Tag, Form, Modal, Button, Toast, Divider, Select } from '@douyinfe/semi-ui';
import { useState, useRef, useEffect } from "react";

import { useAppContext } from "@/context/context";
import i from "@/api/axiosInstance";
import { IconHelpCircle } from '@douyinfe/semi-icons';

export default function AddPlan({ setSlideVisible, getList, planArray, planData }) {


  



    const { setLoadingShow } = useAppContext();  //加载层隐藏或者显示

 

   

    const { Text } = Typography;
    const formApi2 = useRef(); //获取添加 

    const [config, setConfig] = useState({});

    const [CurrentPlan, setCurrentPlan] = useState({PlanID:"1",tips:""}); //方案提示

    const [SidClassify, setSidClassify] = useState([]); //不重复的sid分类

    const [Goods1, setGoods1] = useState([]); //用券商品列表 
    const [Goods2, setGoods2] = useState([]); //原价商品列表 

    const [temp1, setTemp1] = useState("");


    useEffect(() => {
 

        i.post("s.php?f=get_SidClassify").then((r) => {
            setSidClassify(r.data);
        })

        //获取设置的信息 设置默认调用门店的值
        i.post("s.php?f=get_settings").then((r) => {
            setConfig(r.data);

           //判断是修改还是添加 
            if (planData){
                //修改 
                formApi2.current.setValues({ ...planData,"默认storeCode": r.data.默认storeCode });  //设置表单值
                setCurrentPlan({PlanID:planData.方案类型});
                setSelectGoodsList(JSON.parse(planData.商品列表));

            }else{
                //新增
            formApi2.current.setValues({ "方案名称": "方案名称1", "方案类型": "1", "方案编码": "A01", "商品名称": "【薯条蘸酱！】圆筒冰淇淋+中薯条", "支付金额": "50", "成本价": "20", "BE类型": "到店取餐", "默认storeCode": r.data.默认storeCode, "余额支付": "否", "排队下单": "是", "使用代理下单": "否", "必须咖啡门店": "否" });  //设置表单值

            }

 
            // 获取原价商品列表
            OriginalGoodsList();

        })
        //获取可用券列表 
        i.post("s.php?f=get_couponGoodsList&limit=10000").then((r) => {
            setGoods1(r.data.data);
        })
 
       

     
      
    }, [])


    function OriginalGoodsList(){  //获取原价商品列表
 


     if (formApi2.current.getValue("默认storeCode").length < 7) { Toast.info({ content: 'StoreCode不正确' }); return false; } //判断商店code是否合规
 
        i.post("s.php?f=MDL_store_menu", { storeCode: formApi2.current.getValue("默认storeCode"), mealtime: formApi2.current.getValue("就餐时间")} ).then((r) => {
 

                if (r.data.code!=200) { Toast.info({ content: '接口错误' }); return false; }; 
                if (r.data.data==undefined) { Toast.info({ content: '没有获取到原价商品列表' }); return false; };



                //提取出菜单下的套餐下的商品

            let tmpe3 = []; 
            for (const v1 of r.data.data.menu) {
                  
                if (v1.categories){  // 有些商品不在productList  如饮品 和其他单点小商品 
                    for (const v2 of v1.categories) { 
                        for (const v3 of v2.productList) {  
                            if (v3.groupList){  //判断商品底部还有没有选项 比如 可口可乐中杯  小杯 有的话 他里面的数据循环提取替换 
                                for (const v4 of v3.groupList) { 
                                    tmpe3.push({productCode: v4.subProductCode, productImage: v3.productImage, productName: v4.showName, priceInfo: { "eatInPrice": v4.priceInfo.eatInPrice, "eatInPriceText": v4.priceInfo.eatInPriceText }});  
                                } 
                            }else{
                                tmpe3.push(v3);  //直接存入商品
                            } 

                        }  
                    }  
                }
  
                tmpe3.push(...v1.productList);  // 追加数组 
                }  
          //  console.log("cc",tmpe3);
            setGoods2(tmpe3);   //提取完成赋值

          //  console.log("原价商品列表", r.data);
        })


    }

 


    function Goods1Select(v, type) { //v:选中的值 用券商品

        let v1 = Goods1[v]; //获取用券商品的信息 
        if (!v1) { console.log("空"); return false; }

        setTimeout(() => { Select1.current.deselectAll(); }, 500);  //清空选择框的值

 
        let t2 = {};  
        t2 = { 下单方式: "使用券", 优惠券ID: v1.优惠券ID, 优惠券券码: v1.优惠券券码, 优惠券名称: v1.优惠券名称, storeCode: formApi2.current.getValue("默认storeCode"), 订单类型: "到店自取", 支付金额: v1.商品价格, 商品名称: v1.商品名称, 商品图片: v1.商品图片, 商品ID: v1.商品ID, 子商品列表: v1.子商品列表, BE类型: "", 是否必选: ["1", "2"].includes(formApi2.current.getValue("方案类型")), 显示的商品: [] };



        setSelectGoodsList([t2, ...SelectGoodsList]);  //添加数据进选中列表 然后渲染

     

    }


    function Goods2Select(v, type) { //v:选中的值  原价商品


        let v1 = Goods2[v]; //获取用券商品的信息 
        if (!v1) { console.log("空"); return false; }

        setTimeout(() => { Select2.current.deselectAll(); }, 500);  //清空选择框的值

        let t2 = {};
        t2 = { 下单方式: "原价下单", 优惠券ID: "", 优惠券券码: "", 优惠券名称: "", storeCode: formApi2.current.getValue("默认storeCode"), 订单类型: "到店自取", 支付金额: v1.priceInfo.eatInPriceText, 商品名称: v1.productName, 商品图片: v1.productImage, 商品ID: v1.productCode, 子商品列表: [], BE类型: "", 是否必选: ["3"].includes(formApi2.current.getValue("方案类型")), 显示的商品: [] };
 

        setSelectGoodsList([t2, ...SelectGoodsList]);  //添加数据进选中列表 然后渲染

      
    }






    const [SelectGoodsList, setSelectGoodsList] = useState([]); //选取列表
    const Select1 = useRef(null);
    const Select2 = useRef(null);
 
    function handleSelectList(value, index, field) {  //处理列表内的数据双向绑定  
        const temp1 = [...SelectGoodsList];
        temp1[index][field] = value;
        setSelectGoodsList(temp1);
    }






    const TableColumns = [
        {
            title: '商品图片',
            dataIndex: '商品图片',
            render: (text, record, index) => {
                return (
                    <Image width="50" src={text} />
                );
            },
        },
        {
            title: '商品名称',
            dataIndex: '商品名称',
            render: (text, row, index) => {

                return <><div>{text}</div> <Tag color='green'>{row.商品ID}</Tag></> ;

            }
        },
       
        {
            title: '下单方式',
            dataIndex: '下单方式',
            width: "60px",
            render: (text, row, index) => {

                return <Tag color='red'>{text}</Tag>;

            }

        },
        {
            title: '指定账号下单',
            dataIndex: '指定账号下单',
            width: "140px",
            render: (text, row, index) => {


            
                return <> <Select value={row.指定账号下单} onChange={(v) => handleSelectList(v, index, '指定账号下单')} style={{ width: '120px', display: row.下单方式 != "原价下单"&&"none" }}>
                 <Select.Option   value="">无</Select.Option>
                    {SidClassify?.map((v,index) => <Select.Option key={index} value={v}>{v}</Select.Option> )}
              
                </Select></>;
            }

        },


        {
            title: '餐厅代码',
            width: "115px",
            dataIndex: 'storeCode',
            render: (text, row, index) => {
                return <> <Input onChange={(v) => handleSelectList(v, index, 'storeCode')} value={row.storeCode} ></Input></>;
            }
        },
        {
            title: '订单类型',
            dataIndex: '订单类型',
        },
        {
            title: '优惠券名称',
            dataIndex: '优惠券名称',
            render: (text, row, index) => {


                return <> <Input onChange={(v) => handleSelectList(v, index, '优惠券名称')} value={row.优惠券名称} ></Input></>;
            }
        },
        {
            title: '支付金额',
            width: "90px",
            dataIndex: '支付金额',
            render: (text, row, index) => {
                return <> <Input onChange={(v) => handleSelectList(v, index, '支付金额')} value={row.支付金额} ></Input></>;
            }
        },

        {
            title: '是否必选',
            dataIndex: '是否必选',
            width: "90px",
            render: (text, row, index) => {

              
                return <Checkbox checked={text} onChange={(e) => handleSelectList(e.target.checked, index, '是否必选')} ></Checkbox>;
            }
        },
        {
            title: '操作',
            dataIndex: 'owner',
            render: (text, row, index) => {
                return (<Space>
                    <Button size="small" onClick={() => showFilterModal(row, index)} theme='solid'>商品筛选</Button>
                    <Button size="small" type='danger' onClick={() => {
                        setSelectGoodsList((v) => {
                            const temp2 = [...SelectGoodsList];
                            temp2.splice(index, 1);
                            return temp2;
                        })

                    }} theme='solid'>删除</Button>
                </Space>);
            },
        }

    ];



    const [ModalVisible, setModalVisible] = useState(false);  //筛选弹框




    const [FilterGoods, setFilterGoods] = useState({}); //筛选的弹窗商品列表
 
    
 
    async function showFilterModal(row, index) {  //表格商品筛选点击时
 
setLoadingShow(true);
 
        let childGoods = [];// 子商品列表  temp 是返回的json的 comboItems
 

            //用券还是原价都获取子商品列表 
            let temp6 = await i.post("s.php?f=get_original_goods", { storeCode: row.storeCode, productCode: row.商品ID, mealtime: formApi2.current.getValue("就餐时间")}).then(v=>v.data);  //获取商品下的数据
        setLoadingShow(false);
        if (temp6.code != 200) { Toast.info({ content: temp6.message }); return false; } //获取数据后赋值
            if (temp6.data.product.comboItems==undefined) { Toast.info({ content: '没有商品了' }); return false; } //获取数据后赋值

            childGoods = temp6.data.product.comboItems;  //赋值 
           

        let temp2 = [];
        for (const v of childGoods) {   //循环读取子商品分类 列表里面的商品  如 饮料 主食 下的商品合在一起

            for (const v2 of v.comboProducts) {
                temp2.push(v2);
            }

        } 

        setFilterGoods({ index: index, data: temp2, refresh:1});  ///设置筛选的商品

        
        setModalVisible(true);  //显示弹窗
  


    }
 

 




    function handleForm() { //处理表单提交
        
         //表单 验证
        formApi2.current.validate().then(async values => {
                //确定按钮点击  有id修改没有ID 添加 
                      


                //判断是否有原价下单的没有设置指定账号
         
            console.log(SelectGoodsList,formApi2.current.getValues());



            let t3=formApi2.current.getValue("方案类型");

            let t4=[0,0];

            SelectGoodsList.map((v)=>{
                  //方案类型2指定账号可以留空
                if (t3!="2"&& v.下单方式 == "原价下单" && (v.指定账号下单 == "" || v.指定账号下单 == undefined)){ 
                    Toast.error({content:"原价下单需要指定一个账号分类下单"});
                  throw new Error("");
                } 

                if(v.下单方式=="使用券"){  //方案类型计数
                    t4[0]++;
                }
                if(v.下单方式=="原价下单"){
                    t4[1]++;
                }

            })
             
            if(t3=="2"||t3=="3"){

                if(t4[0]<1||t4[1]<1){

                    Toast.error({content:"需要选择用券商品和原价下单商品"});
                    throw new Error("");

                }

            }





            setLoadingShow(true);
   
            //判断是修改还是新增 有ID就是修改
            const r = await i.post("s.php?f=handlePlan", { ...values, 商品列表: JSON.stringify(SelectGoodsList) });
                Toast.info({ "content": r.data.msg });

            setLoadingShow(false);//隐藏加载层  

            if (r.data.code == 1) {
              //隐藏弹出层
                setSlideVisible(false);
                getList(1); //刷新列表页

            }
 
                  
            })
            .catch(errors => { })



    }

 

 
    return (<>

        <Form layout='horizontal' getFormApi={formApi => formApi2.current = formApi}>
            <Row type='flex' gutter={[15, 15]}>

                <div style={{ display: "none" }}> <Form.Input field='id' /></div>

                <Col span={12}> <Form.Input rules={[{ required: true, message: '不能为空' }]} field='方案名称'   /></Col>
                <Col span={12}> <Form.Input rules={[{ required: true, message: '不能为空' }]} field='方案编码'   /></Col>
                <Col span={8}> <Form.Input rules={[{ required: true, message: '不能为空' }]} field='商品名称'   /></Col>

                <Col span={8}> <Form.InputNumber rules={[{ required: true, message: '不能为空' }]} field='支付金额' label="最大支付金额"   /></Col>
                <Col span={8}> <Form.InputNumber rules={[{ required: true, message: '不能为空' }]} field='成本价'  /></Col>


                <Col span={12}>
                    <Form.RadioGroup   field="余额支付">
                        <Form.Radio value="是">是</Form.Radio>
                        <Form.Radio  value="否">否</Form.Radio>
                    </Form.RadioGroup>
                </Col>
                <Col span={12}>
                    <Form.RadioGroup field="排队下单">
                        <Form.Radio  value="是">是</Form.Radio>
                        <Form.Radio value="否">否</Form.Radio>
                    </Form.RadioGroup>

                </Col>

                <Col span={12}>
                    <Form.RadioGroup field="使用代理下单">
                        <Form.Radio value="是">是</Form.Radio>
                        <Form.Radio  value="否">否</Form.Radio>
                    </Form.RadioGroup>
                </Col>


                <Col span={12}>
                    <Form.RadioGroup field="必须咖啡门店">
                        <Form.Radio value="是">是</Form.Radio>
                        <Form.Radio value="否">否</Form.Radio>
                    </Form.RadioGroup>
                </Col>
               

                <Col span={12}>
                    <Form.Input field='默认storeCode' onBlur={e => { setTimeout(OriginalGoodsList, 100); }}  placeholder="StoreCode" label='调用门店(storeCode)' /></Col>
                <Col span={12}> <Form.DatePicker type="dateTime" field="就餐时间"  onChange={time => { setTimeout(OriginalGoodsList, 100);}} label='就餐时间' placeholder='请选择就餐时间' /></Col>



                <Col span={24}>
                    <Form.Select field="方案类型" placeholder="请选择方案" rules={[{ required: true, message: '不能为空' }]} onChange={v =>{ setCurrentPlan({PlanID:v, tips: planArray[v].desc }); setSelectGoodsList([]); }} style={{ width: "100%" }} label='方案类型'  >

                        {Object.entries(planArray).map(([k, v]) => <Form.Select.Option key={k} value={k}>{v.标题}</Form.Select.Option>)}
 
                    </Form.Select>
                    <Typography.Text type="tertiary">{CurrentPlan.tips}</Typography.Text>
                </Col>
                <Col style={{ display: ["5", "6"].includes(CurrentPlan.PlanID) && "none" }} span={24}>
                    <Form.Select onChange={(v) => Goods1Select(v, "用券商品")} filter ref={Select1} field='Goods1' label='用券商品（选择后会自动加入商品列表）' style={{ width: "100%" }} placeholder="请选择商品(可以搜索)"  >

                        {Goods1.map((v, index) => <Form.Select.Option value={index} key={v.商品ID + index}> {v.商品名称}（{v.商品ID}）----  {v.优惠券名称}（{v.优惠券券码}） </Form.Select.Option>)}

                    </Form.Select>
                </Col>




                <Col style={{ display: ["1", "4"].includes(CurrentPlan.PlanID) && "none" }} span={24}> <Form.Select filter  onChange={(v) => Goods2Select(v, "原价商品")} value={temp1} field='Goods2' ref={Select2} label='原价商品' style={{ width: "100%" }} placeholder="请选择商品(可以搜索)"  >
            
                    {Goods2.map((v, index) => <Form.Select.Option value={index} key={index}>  {v.productName}({v.productCode})----￥{v.priceInfo.eatInPriceText}</Form.Select.Option>) }
                   
                    
                </Form.Select></Col>



                <Table style={{ marginTop: "15px", width: "100%" }} columns={TableColumns} dataSource={SelectGoodsList} pagination={false} />



                <Space style={{ width: "100%", marginTop: "40px", justifyContent: "flex-end", padding: "15px 0", borderTop: "1px solid #eee" }}> 
                    
                    
                    <Form.Select field="BE类型" noLabel={true} prefix="BE类型" >
                    <Select.Option value="到店取餐">到店取餐</Select.Option>  
                </Form.Select>
                    <Button theme='solid' type='primary' onClick={handleForm} style={{ width: 140 }}>确认</Button> </Space>


            </Row>
        </Form>
      


        <Modal title={<>商品筛选  <Tooltip content={'如果不选就显示全部商品,选中就只显示选中的商品'}>  <IconHelpCircle /> </Tooltip></>} visible={ModalVisible} footer={
            <>  <Button onClick={() => {

                const temp3 = SelectGoodsList;  //勾选差价大于0的商品
                FilterGoods.data.map((v)=>{ 
                    if (v.diffPrice==0){
                    temp3[FilterGoods["index"]]["显示的商品"].push(v.code);  //点击确定后 根据索引 把勾选的商品加入到显示的商品列表 
                    }
                })
                setSelectGoodsList(temp3);   //赋值
  
                Toast.info({ "content":"已设置成功"});
                setFilterGoods({ ...FilterGoods, refresh: Math.random() }); //更新数据


            }} type="primary" theme='solid'  >
             勾选差价等于0的商品
            </Button> <Button onClick={() => setModalVisible(false)} type="primary" >关闭</Button> </>
        }  onCancel={() => setModalVisible(false)}  >


            <div style={{ maxHeight: "60vh", overflow: "auto" }} >

                <Table columns={[
                    
                    {
                        title: '',
                        dataIndex: '',
                        render: (text, record, index) => {

                      //判断是否在隐藏的列表里面
  
                          
                            return (
                                <div>
                                    <Checkbox value={record.code} checked={SelectGoodsList[FilterGoods.index]["显示的商品"].includes(record.code)} onChange={e => {


                                        const temp3 = SelectGoodsList;  
                                        if (e.target.checked) { 
                                            temp3[FilterGoods["index"]]["显示的商品"].push(e.target.value);  //点击确定后 根据索引 把勾选的商品加入到显示的商品列表
                                        } else {
                                            //取消选中
                                            temp3[FilterGoods["index"]]["显示的商品"] = temp3[FilterGoods["index"]]["显示的商品"].filter(item => item !== e.target.value); 
                                        }
                                             
                                        setSelectGoodsList(temp3);   //赋值
                                        Toast.info({ "content": "已设置成功" });
                                    setFilterGoods({...FilterGoods,refresh:Math.random()});
                                      

                                        
                                            }}></Checkbox>
                                </div>
                            );
                        },  

                    },
                    {
                    title: '商品名称',
                    dataIndex: 'name'

                },
                {
                    title: '商品ID',
                    dataIndex: 'code'
                }, {
                    title: '差价',
                    dataIndex: 'diffPriceText'

                }]} dataSource={FilterGoods.data}  pagination={false} />

            </div>


        </Modal>

    </>);

}