
import { useAppContext, Extract_sid } from "@/context/context";
import { useState, useRef, useEffect } from "react";
import { Table, Space, Popconfirm, Tag, Dropdown, SideSheet, Form, Modal, Button, Toast, Divider } from '@douyinfe/semi-ui';
import i from "@/api/axiosInstance";


export default function AddCouponCode({ sync_coupon, SlideVisible, setSlideVisible,couponID }) {



    const formApi2 = useRef(); //获取formapi
    const [couponList, setCouponList] = useState([]);
    const [planList, setPlanList] = useState([]);

 

    if (couponID) {  //判断是否有指定ID
        setTimeout(() => {
            formApi2.current.setValues({ 优惠券ID: couponID });
        }, 200); 
    }
   
    useEffect(()=>{
      
        
        i.post("s.php?f=get_couponList&limit=10000").then((r) => { //获取券列表
            setCouponList(r.data.data);
        })

        i.post("s.php?f=get_planList&limit=10000").then((r) => { //获取方案列表
            setPlanList(r.data.data);
        })



    }, [])

 

    async function handleForm() { //处理表单提交

        //表单 验证
        formApi2.current.validate().then(async values => {

         //   console.log(values);

            //添加syncList 循环同步优惠券


            let syncList = [];  //循环同步

            let e_sid = Extract_sid(values.sid列表);
            formApi2.current.setValue("sid列表", e_sid); 

            for (const v of e_sid.split("\n")) {    //添加数组
                syncList.push({ 方案编码: values.方案编码, 备注: values.备注, sid: v.trim(), 优惠券ID: values.优惠券ID });
            }
 
            let r = await sync_coupon({ syncList: syncList });  //同步优惠券


        }).catch((error) => { console.log(error); });



    }


    return (<SideSheet motion={false} width="700px" title="导入数据" visible={SlideVisible} headerStyle={{ borderBottom: '1px solid var(--semi-color-border)' }} onCancel={() => setSlideVisible(false)} >

        <Form getFormApi={formApi => formApi2.current = formApi}   >

            <Form.Select field="优惠券ID" label="导入指定券" placeholder="请选择券名称，只导入选中的券" style={{ width: "100%" }} rules={[{ required: true, message: '不能为空' }]}    >

                <Form.Select.Option value="全部">导入账号下全部的券</Form.Select.Option>
                {couponList.map(v => <Form.Select.Option key={v.优惠券ID} value={v.优惠券ID}>{v.优惠券名称} ({v.优惠券ID})</Form.Select.Option>)}


            </Form.Select>

            <Form.Select field="方案编码" label="限定方案" placeholder="限制使用在指定的方案" style={{ width: "100%" }} showClear={true}   >

                {planList.map(v => <Form.Select.Option key={v.方案编码} value={v.方案编码}>{v.方案名称}（{v.方案编码}）</Form.Select.Option>)}


            </Form.Select>

            <Form.Input field='备注' />

            <Form.TextArea field='sid列表' rules={[{ required: true, message: '不能为空' }]} placeholder='批量导入sid的券 一行一个sid ' />

            <Space style={{ width: "100%", marginTop: "40px", justifyContent: "flex-end", padding: "15px 0", borderTop: "1px solid #eee" }}>

                <Button theme='solid' type='primary' onClick={handleForm} style={{ width: 140 }}>确认</Button> </Space>


        </Form>

    </SideSheet>);


}