// src/api/axiosInstance.js
import axios from 'axios';




const instance = axios.create({
     // baseURL: 'http://localhost/LS/react/mdl/HT', // 替换为你的API基础URL
baseURL: `${window.location.protocol}//${window.location.hostname}/s/`,
    timeout: 100000, // 请求超时时间
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded', 
        "T":localStorage.t
        // 其他默认请求头
    },
    responseType: 'json'

});

// 请求拦截器
instance.interceptors.request.use(
    config => {
        // 在发送请求之前做些什么
        // 例如，添加认证 token
       

        return config;
    },
    error => {
        // 对请求错误做些什么
        return Promise.reject(error);
    }
);

// 响应拦截器
instance.interceptors.response.use(
    response => {
        // 对响应数据做点什么 
 
if(response.data.code==-1){
     alert("请在登录界面重新登录");

     return false;
}


        return response; //直接返回数据
    },
    error => {
        // 对响应错误做点什么
        if (error.response) {
            // 请求已发出，但服务器响应的状态码不在 2xx 范围内
            console.error('Server responded with status:', error.response.status);
        } else if (error.request) {
            // 请求已发出，但没有收到响应
            console.error('No response received:', error.request);
        } else {
            // 发生在设置请求时的错误
            console.error('Error setting up request:', error.message);
        }
        return Promise.reject(error);
    }
);

export default instance;