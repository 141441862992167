
import { Table, Space, Popconfirm, Tag, Dropdown, Tooltip, Form, Modal, Button, Toast, Divider } from '@douyinfe/semi-ui';
import { useState, useRef, useEffect } from "react";
import { IconTreeTriangleDown, IconHelpCircle } from "@douyinfe/semi-icons";
import i from "@/api/axiosInstance";
import { useAppContext } from "@/context/context";

import AddBenefit from "@/components/AddBenefit";

export default function Main() {



    const { setLoadingShow } = useAppContext();  //加载层隐藏或者显示 

    const columns = [
        
        {
            title: '分类',
            dataIndex:"分类"
        },
        {
            title: '权益名称',
            dataIndex: '权益名称', 
        render: (text, record, index) => {
            return (<><div>{text}</div> {record.置顶==1 && <Tag color="light-blue" >已置顶</Tag>} </>)

        }
        },
 
        {
            title: 'sid',
            dataIndex: 'sid',
            render: (text, record, index) => {
                return (<><div>{text}</div> <Tag color="green" >{record.手机号} </Tag>  <Tag color="white" >余额：￥{record.账户余额} </Tag> </>)

            }
        },
        {
            title: '权益状态',
            dataIndex: '权益状态',
            render: (text, record, index) => {

                return (<>{text == "异常" ? <Tooltip content={record.异常通知}><Tag color="red" >{text} <IconHelpCircle style={{ marginLeft: 5 }} /></Tag>   </Tooltip> : <Tag color="green" >{text}</Tag>}</>)


            }
        },
        {
            title: '入库状态',
            dataIndex: '入库状态',
            render: (text, record, index) => {
 
                return (<Tag color={text == "正常" ? "green" : "red"} > {text} </Tag>)

            }
        },
        {
            title: '会员到期时间',
            dataIndex: '会员到期时间'
        }, 
        {
            title: '最新使用时间',
            dataIndex: '最新使用时间'
        },

        {
            title: '今日可用次数',
            dataIndex: '今日可用次数'
        },
        {
            title: '最新同步时间',
            dataIndex: '最新同步时间'
        },
        
       
        {
            title: '备注',
            dataIndex: '备注'
        },
       
        {
            title: '操作',
            dataIndex: 'owner',
            render: (text, row, index) => {

                return (<Space>


                    <Button size="small" onClick={() => {
                        sync_function({ syncList: [{ sid: row.sid, 分类: { 分类名称: row.分类,权益列表:[[row.benefitId,""]]}}]})
                    }}
                        theme='solid'>同步</Button>
                    <Dropdown
                        render={
                            <Dropdown.Menu>
                              
                                <Popconfirm
                                    title="是否要删除"
                                    onConfirm={() => { deleteDate(row.id) }}
                                >
                                    <Dropdown.Item >删除</Dropdown.Item>
                                </Popconfirm>
                            </Dropdown.Menu>
                        } trigger="hover" position="bottomRight">




                        <Button size="small" theme="light" type="primary" icon={<IconTreeTriangleDown />}></Button>
                    </Dropdown>
                </Space>);
            },
        }
    ];



    function change_status1(field,state) { // value 入库或者出库状态

        let list = [];
        if (selectedRows.length < 1) { Toast.info({ content: "最少需要选中一条数据" }); return false; }
        for (const v of selectedRows) {
            list.push({ id: v.id, field: field, b: "mdl_benefit", value: state });
        } 
        i.post("s.php?f=update_data", { list: list }).then(r => { 
            Toast.info({ "content": r.data.msg }); 
            if (r.data.code == 1) { getList(); } 
        })

    }




    async function deleteDate(id) {

        const r = await i.post("s.php?f=delete_data", { id: id, b: "mdl_benefit" });
        Toast.info({ "content": r.data.msg });
        getList();
    }




    const formApi1 = useRef(); //获取搜索表单1 API

 

    const [data, setData] = useState([]);

    let limit = 30; //分页页数
    async function getList(page) {

        page = page ? page : currentPage;

        const r1 = await i.post(`s.php?f=get_benefitList&limit=${limit}&page=${page}`, formApi1.current.getValues());


        setData(r1.data);

    }
     
    useEffect(() => { 
        formApi1.current.setValues({ "入库状态": "0" }); 
        getList(1);  //获取第一页商品

 
    }, [])


    const [SlideVisible, setSlideVisible] = useState(false); //侧边栏显示或隐藏

 

    async function sync_function(values) {  //同步优惠券 
        setLoadingShow(true);
        let r = await i.post("s.php?f=sync_benefit", { ...values });
        setLoadingShow(false);

        Modal.info({
            title: '操作成功', hasCancel: false, 
            width:"650px",
            content: <><p style={{ fontSize: "16px", lineHeight: "1.5" }}>
                新增权益数量:{r.data.succeed} <br />
                更新权益数量:{r.data.repetition} <br />
                 {r.data.error > 0 && <Tag>向数据库添加数据时出错,数量:{r.data.error}</Tag>}
                {r.data.info.map((v, index) => <Tag key={index} color="red">{v}</Tag>)}
            </p></>
        });

        getList();


        return r;


    }


    let classify = [
        {
            分类名称: "麦金套餐", 权益列表: [
                ["2024102909242600019", "超值四件套","麦金卡"]
            ]
        },  {
            分类名称: "麦金早餐", 权益列表: [
                ["2024102909260900020", "6折早餐", "麦金卡"]
            ]
        }, 
        {
            分类名称: "9.9指定麦咖啡", 权益列表: [
                ["2024102909301700021", "9.9元购指定麦咖啡", "麦金卡"] 
            ]
        },  
        {
            分类名称: "5元福利金到店", 权益列表: [
                ["2024102909330100023", "满减福利金", "麦金卡"]
            ]
        }, {
            分类名称: "早餐卡-早餐", 权益列表: [
                ["EA9CBAEA882F3FF125618F50309EAC51", "早餐6折起（双月卡）","早餐双月卡"],
                ["4339D16F35631E6529CF5B7F6FEAD580", "早餐6折起（单月卡）", "早餐单月卡"]

            ]
        }, {
            分类名称: "早餐卡-咖啡", 权益列表: [
                ["44079B6A46A85FCF16E5BEADA697C498", "麦咖啡天天9.9", "早餐双月卡"], 
                ["A5A858B323179FAD90C5D0B860B8A8E7", "麦咖啡天天9.9", "早餐单月卡"], 
            ]
        }, {
            分类名称: "余额号", 权益列表: [
                ["", "查找账号余额","余额号"]
            ]
        },  
    ];




    const [currentPage, setPage] = useState(1);

    const [selectedRows, setSelectedRows] = useState([]);
    return (<>
        <Space>
            <Form getFormApi={formApi => formApi1.current = formApi} layout='horizontal' >

                <Form.Input
                    placeholder="输入关键字搜索"
                    field='key'
                    label={{
                        text: '搜索'

                    }}
                    style={{ width: 176 }}
                />
                <Form.Select field="分类" >
                    <Form.Select.Option value="0">全部</Form.Select.Option>
                    {classify.map((v, index) => <Form.Select.Option key={v.分类名称 + "-" + index} value={v.分类名称}>{v.分类名称}</Form.Select.Option>)}
                </Form.Select>

                <Form.Select field="入库状态" label="入库状态" >
                    <Form.Select.Option value="0">全部</Form.Select.Option>
                    <Form.Select.Option value="正常">正常</Form.Select.Option>
                    <Form.Select.Option value="出库">出库</Form.Select.Option>
                </Form.Select>

                <Form.DatePicker field="会员到期时间" style={{ width: 260 }} onChange={(date) => { console.log(date); }} type="dateRange" label='到期时间筛选' />



                <Space style={{ display: 'flex', alignItems: 'flex-end' }}>
                    <Button type='primary' onClick={() => getList(1)} theme='solid'>搜索</Button>
                    <Button onClick={() => { setTimeout(() => { getList(1); }, 200); }} htmlType="reset">重置</Button>

                    <Button type='primary' style={{ marginLeft: 50 }} onClick={() => setSlideVisible(true)} theme='solid' >导入</Button>



                    <Button type='warning' onClick={() => { change_status1("入库状态","出库") }} theme="solid"  >出库</Button>
                    <Button type='warning' onClick={() => { change_status1("入库状态", "正常") }} theme="solid"  >入库</Button>


                    <Button type='warning'  onClick={() => {
                        let t1 = [];
                        for (const v of selectedRows) {
                            t1.push({ sid: v.sid, 分类: {分类名称:v.分类,权益列表: [[v.benefitId, ""]] } });
                        }
                        sync_function({ syncList: t1 });

                    }} theme='solid' >批量同步</Button>

                    <Button type='warning' onClick={() => { change_status1("置顶","1") }} theme="solid" >置顶(优先使用)</Button>
                    <Button type='warning' onClick={() => { change_status1("置顶", "0") }} theme="solid" >取消置顶</Button>



                    <Popconfirm
                        title="是否要同步所有权益"
                        onConfirm={() => { sync_function({ "syncAll": true }) }}
                    >
                        <Button type='primary' theme='solid' >同步所有权益</Button>
                    </Popconfirm>




                    <Popconfirm
                        title="是否要删除"
                        onConfirm={() => {


                            let t1 = [];
                            for (const v of selectedRows) {
                                t1.push(v.id);
                            }
                            deleteDate(t1.join(","));


                        }}
                    >
                        <Button type='danger' theme='solid' >批量删除</Button>

                    </Popconfirm>



                </Space>



            </Form>





        </Space>
        <Divider margin='12px'>
        </Divider>
        <Table columns={columns} scroll={{ y: window.innerHeight - 285 }} rowSelection={{
            onChange: (selectedRowKeys, selectedRows) => {
                setSelectedRows(selectedRows);
            },
        }} dataSource={data.data} rowKey="id" pagination={{
            currentPage,
            pageSize: limit,
            total: data.count,
            onPageChange: (page) => { setPage(page); getList(page); },
        }} />




        <AddBenefit SlideVisible={SlideVisible} classify={classify} setSlideVisible={setSlideVisible} sync_function={sync_function}></AddBenefit>

         


    </>);

}