
import { Table, Space, Typography, Image, Popconfirm, SideSheet, Row, Col, Tag, Form, Modal, Button, Toast, Divider } from '@douyinfe/semi-ui';
import { useState, useRef, useEffect } from "react";
import i from "@/api/axiosInstance";

import { useAppContext } from "@/context/context";


import AddPlan from "@/components/AddPlan";
export default function Main() {



    const { setLoadingShow } = useAppContext();  //加载层隐藏或者显示 

    const [planData, setPlanData] = useState(false);//方案数据 修改会用到





    //方案列表
    const planArray = {
        "1": { 标题: "1个或多个用券商品", 用券商品数量: 99, 原价商品数量: 0, desc: "该规则适用于将多个用券商品组合成一个方案，每个用券商品单独下单。注意不要包含任意消费后才能使用的0元券商品" },
        "2": { 标题: "1个原价商品+1个用券商品", 用券商品数量: 1, 原价商品数量: 1, desc: "该规则适用于将1个原价商品+1个用券商品组合成一个方案，两个商品在同一个订单上，出一个取餐码。例如：随心配+APP 0元辣翅的组合" },
        "3": { 标题: "1个原价商品+多个用券商品任选其一", 用券商品数量: 1, 原价商品数量: 1, desc: "该规则适用于将1个原价商品+多个用券商品组合成一个方案（多个用券商品只能任选其一），两个商品在同一个订单上，出一个取餐码。例如：随心配+（APP 0元辣翅的组合/APP 0元中薯条）" },
        "4": { 标题: "多个用券商品任选其一", 用券商品数量: 0, 原价商品数量: 1, desc: "该规则适用于将多个用券商品组合成一个方案，用户只能选其中一个商品下单。适用于多个感谢券组成一个方案，用户选一个商品下单" },
        "5": { 标题: "1个或多个原价商品", 用券商品数量: 0, 原价商品数量: 99, desc: "该规则适用于1个或多个原价商品组成一个方案，商品下在同一单上，出一个取餐码" },
        "6": { 标题: "1个或多个原价商品任选其一", 用券商品数量: 0, 原价商品数量: 1, desc: "该规则适用于1个或多个原价商品组成一个方案，下单时用户只能选择其中一个商品，出一个取餐码" }
    };




    const columns = [
        {
            title: '方案名称',
            width: 120,
            dataIndex: '方案名称',
        },
        {
            title: '方案编码',
            width:90,
            dataIndex: '方案编码',
        },
        {
            title: '方案类型',
            width: 90,
            dataIndex: '方案类型',
        render: (text, record, index) => {
 
            return <>{planArray[text]["标题"]}</>;

        }
        },
        {
            title: '商品名称',
            width: 200,
            dataIndex: '商品名称',
        },
        {
            title: '支付金额',
            width: 100,
            dataIndex: '支付金额',
        },  
        {
            title: '方案商品',
            dataIndex: '',  
            width:500,
            render: (text, record, index) => { 

                let t1 = JSON.parse(record.商品列表);

 


                return (<Space>{t1.map((v, index) => <Tag key={index} avatarSrc={v.商品图片} size="large" ><b style={{marginRight:5,color:"#666"}}>{v.下单方式}</b>{v.商品名称}</Tag>)}</Space>)

            }
        }, {
            title: '操作',
            dataIndex: 'owner',
            fixed: 'right',
            width:110,
            render: (text, row, index) => {
                return (<Space> 
                    <Button size="small" type='primary' onClick={()=>{ setPlanData(row); setSlideVisible(true); }}  theme='solid'>修改</Button>
                    <Popconfirm
                        title="是否要删除"
                        onConfirm={() => { deleteDate(row.id) }}
                    >
                        <Button size="small" type='danger' theme='solid'>删除</Button>
                    </Popconfirm>
                </Space>);
            },
        }
 
    ];




    async function deleteDate(id) {

        const r = await i.post("s.php?f=delete_data", { id: id, b: "mdl_plan" });
        Toast.info({ "content": r.data.msg });
        getList();
    }




    const formApi1 = useRef(); //获取搜索表单1 API 

    const formApi2 = useRef(); //获取添加 
 
    useEffect(() => { 
         

        getList();
      
    }, [])


 
    const [SlideVisible, setSlideVisible] = useState(false); //侧边栏显示或隐藏


         const [planTip,setPlanTip]=useState("");






    const [data, setData] = useState([]);
    const [currentPage, setPage] = useState(1);

    let limit = 30; //分页页数
    async function getList(page) {  //方案列表
 


        page = page ? page : currentPage; 
        const r1 = await i.post(`s.php?f=get_planList&limit=${limit}&page=${page}`, formApi1.current.getValues());


        setData(r1.data.data);

    }



 
    return (<>
        <Space>
 
            <Form  getFormApi={formApi => formApi1.current = formApi} layout='horizontal' >

                <Form.Input
                    placeholder="输入关键字搜索"
                    field='key'
                    label={{
                        text: '搜索'

                    }}
                    style={{ width: 176 }}
                />

                <div style={{ display: 'flex', alignItems: 'flex-end' }}>
                    <Button type='primary' onClick={() => getList(1)} theme='solid' style={{ marginRight: 4 }}>搜索</Button>
                    <Button onClick={() => { setTimeout(() => { getList(1); }, 200); }} htmlType="reset">重置</Button>


                    <Button type='primary' theme='solid' onClick={() => { setPlanData(false); setSlideVisible(true);}} style={{ margin: "0 30px" }}>新增方案</Button>
                </div>
 
            </Form>


        </Space>
        <Divider margin='12px'>
        </Divider>
        <Table columns={columns} scroll={{ y: window.innerHeight - 285 }} dataSource={data} pagination={{
            currentPage,
            pageSize: limit,
            total: data.count,
            onPageChange: (page) => { setPage(page); getList(page); },
        }}   />


        <SideSheet motion={false} width="85vw" title={planData ? "修改方案" :"新增方案"} visible={SlideVisible} headerStyle={{ borderBottom: '1px solid var(--semi-color-border)' }} onCancel={() => setSlideVisible(!SlideVisible)} >
            <AddPlan planArray={planArray} planData={planData} getList={getList} setSlideVisible={setSlideVisible}></AddPlan>

           


        </SideSheet>
    </>);



}