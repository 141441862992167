
import { Table, Space, Image, Tag, SideSheet, Row, Form, Popconfirm, Modal, Button, Toast,Divider } from '@douyinfe/semi-ui'; 
import { useState,useRef, useEffect } from "react";
import i from "@/api/axiosInstance"; 


import { useAppContext } from "@/context/context";

export default function Main() {
    


    const { setLoadingShow } = useAppContext();  //加载层隐藏或者显示 

    const columns = [
        {
            title: 'id',
            dataIndex: 'id',
            width:60
        },
     
        {
            title: '阿奇索应用名称',
            dataIndex: '阿奇索应用名称',
        },
        {
            title: '阿奇索appid',
            dataIndex: '阿奇索appid',
        },
       
        {
            title: '阿奇索appSecret',
            dataIndex: '阿奇索appSecret',
        },
        {
            title: '平台accessToken',
            dataIndex: '平台accessToken',
            width: 300, render: (text, row, index) => {
                return (<div style={{ wordBreak: "break-all" }}>{text}</div>);
            }
        }, {
            title: '售卖平台',
            width: 100, 
            dataIndex: '售卖平台',
        }, {
            title: '店铺名称',
            dataIndex: '店铺名称',
        }, {
            title: '推送地址',
            dataIndex: '推送地址',
            width:300, 
            render: (text, row, index) => {
                return (<div style={{wordBreak:"break-all"}}>{text}</div>);
            }
        } , 
        {
            title: '操作',
            fixed: 'right', 
            render: (text, row, index) => {
                return (<Space>

                    <Button size="small"  onClick={()=>{
                        setVisible1(true);   //显示侧边栏
                        setSideSheetTitle("修改数据");
                        setTimeout(() => {
                            formApi2.current.setValues(row);  //修改赋值给输入框
                        }, 150);
                      


                    }} theme='solid'>修改</Button>
                    <Popconfirm
                        title="是否要删除" 
                        onConfirm={() => { deleteDate(row.id) }}
                    > 
                        <Button size="small" type='danger'  theme='solid'>删除</Button>
                    </Popconfirm>
              
                </Space>);
            }
        }
    ];
      


 
async function deleteDate(id){

    
    const r = await i.post("s.php?f=delete_data", { id:id, b:"mdl_store"});
    Toast.info({ "content": r.data.msg });
    
    getList();

}
 
    const formApi2 = useRef(); //获取formapi



    const [data, setData] = useState([]);
 
async function getList(){   //获取数据列表
  
    
    const r1 = await i.post("s.php?f=get_storeList",{});

   
    setData(r1.data.data);
   
}

 
useEffect(()=>{ 
   getList();  //刷新 
},[])


  


         const [visible1,setVisible1]=useState(false);


    function handleForm(){ //处理表单提交


        formApi2.current.validate()
            .then(async values => {  
                  //确定按钮点击  有id修改没有ID 添加 
               const r=await i.post("s.php?f=handleStore",values); 
                Toast.info({ "content": r.data.msg });
                if (r.data.code==1){ 
                   setVisible1(false);  //隐藏
               }

                getList(); //更新

                

            })
            .catch(errors => {  }) 



    }


    const [SideSheetTitle, setSideSheetTitle] = useState("");

    return (<>
       
        <Space>  <Button type='primary' htmlType='submit' theme='solid' onClick={() => { setVisible1(true); setSideSheetTitle("添加数据"); }}   >添加</Button></Space>
      <Divider margin='12px'> 
            </Divider>
        <Table columns={columns} dataSource={data} scroll={{ y: window.innerHeight - 285 }} pagination={false} />





        <SideSheet title={SideSheetTitle} onCancel={()=>{setVisible1(false)}} visible={visible1} >
          

            <Form getFormApi={formApi => formApi2.current = formApi}> 
              
                <div style={{ display: "none" }}>  <Form.Input field='id' /></div>
                <Form.Input field='阿奇索应用名称' rules={[{ required: true }]} label='阿奇索应用名称' />
                <Form.Input field='阿奇索appid' rules={[{ required: true }]} label='阿奇索appid' />
                <Form.Input field='阿奇索appSecret' rules={[{ required: true }]} label='阿奇索appSecret' />
                <Form.Input field='平台accessToken' rules={[{ required: true }]} label='平台accessToken' />

                <Form.Input field='售卖平台' rules={[{ required: true }]} label='售卖平台' />
                <Form.Input field='店铺名称' rules={[{ required: true }]} label='店铺名称' />
                <Form.Input field='推送地址' rules={[{ required: true }]} label='推送地址' />  
            </Form>

            <Space style={{ width: "100%", marginTop: "40px", justifyContent: "flex-end", padding: "15px 0", borderTop: "1px solid #eee" }}>
                <Button theme='solid' type='primary' onClick={handleForm} style={{ width: 140 }}>确认</Button> </Space>


        </SideSheet>








    </>);

}