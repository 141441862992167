

import "./login.css";

import { IconUser, IconLock } from "@douyinfe/semi-icons";
import { Button, Toast, SideSheet, Checkbox } from "@douyinfe/semi-ui"
import i from "../../api/axiosInstance";
import { useState } from "react";
import { Router, Route, useNavigate, Routes } from "react-router-dom";


export default function Account() {

    const navigate = useNavigate(); 

function Login(){


    const [LoginForm, setLoginForm] = useState({"u":"","p":""});

    

    function handleForm(e){  //输入框输入双向绑定


        const {name,value}=e.target;

        setLoginForm({ ...LoginForm, [name]: value });  //双向绑定
 

    }
    
   async function loginAction() {
  

      const d=await i.post("s.php?f=manage_login",{...LoginForm});
         
 
     


       if (d.data.code==1){
 //登录成功

 localStorage.t=d.data.t;   
localStorage.et = d.data.expiration_time;   

           navigate("/ManageHome");

       }else{

           Toast.info({ content: d.data.msg, theme: 'light' }); //给出错误提示

       }
 
    }
  

    return (<>
        <div className="account_1" >


            <div className="account_img1">  <img src='/img/undraw_donut_love_kau1.svg' /></div>

            <div className="account_title">账号密码登录</div>


            <div className="account_item">   <IconUser /><input value={LoginForm.u} name="u" onInput={handleForm} placeholder="用户名" type="text" className="account_i1" /></div>

            <div className="account_item">   <IconLock /> <input value={LoginForm.p} name="p" onInput={handleForm}  placeholder="密码" type="text" className="account_i1" /></div>

            <Button size='large' onClick={loginAction} style={{ padding: "0 50px", width: "100%", marginTop: "20px", background: "#232429" }} theme="solid" icon="">
                登 录
            </Button>
 

        </div >
 
    </>);


}
 

    return (<><Login></Login></>);

}